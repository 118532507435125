import React, {
  useEffect,
  useState,
  useRef,
  Suspense,
  lazy,
  memo,
  useCallback,
} from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "../TableView/Table.css";
import { Card, Button, Modal } from "react-bootstrap";

import useScrollSpy from "react-use-scrollspy";
import AxiosService from "../../Utils/AxiosService";

import DetailRowNoMemo from "./DetailRow";
import TableCellNoMemo from "./TableCell";

import Dosage from "./Types/Dosage";
import AgentRow from "./Types/AgentRow";

import Sidebar from "./Sidebar/Sidebar";
import Comments from "./Components/Comments";
import ExpandToggle from "./Components/ExpandToggle";
import Allcomments from "./Components/Allcomments";
import LocalStorageService from "../../Auth/auth_service/AuthServiceStorage";
import CommentsWithTree from "./Components/CommentsWithTree";
import ValidationComments from "./Components/ValidationComment";
import FloatingSaveButton from "../../Components/FlotingSave/FloatingSaveButton";
import FloatingDownloadBtn from "../../Components/FloatingDownload/FloatingDownloadBtn";
import EditableTableCellNoMemo from "./EditableTableCell";
import EditableDetailRowNoMemo from "./EditableDetailRow";
import EditedReport from "./Components/EditedReport";
import loadable from "@loadable/component";
import CropEvent from "../TableView/Types/CropEvent";
import jp from "jsonpath";
import _ from "lodash";

import DirectionForUseParentElement from "./Types/DirectionForUseParentElement";
import {
  faInfoCircle,
  faToggleOff,
  faToggleOn,
  faQuestionCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LabelWithInfoTooltip from "./Components/InfoToolTips";
import CvAdditionComments from "./Components/CvAdditionComment";
import ShowSectioncomment from "./Components/ShowSectionComment";
import InputFileLinks from "./Components/InputFileLinks";

const DirectionsForUse = React.lazy(() => import("./Types/DirectionsForUse"));
const AgentNoMemo = React.lazy(() => import("./Types/Agent"));
const AgentMultipleNoMemo = React.lazy(() => import("./Types/AgentMultiple"));

const TableView = ({ data, label, editFlag, onDataChange }) => {
  const DetailRow = memo(DetailRowNoMemo);
  const TableCell = memo(TableCellNoMemo);
  const EditableTableCell = memo(EditableTableCellNoMemo);
  const EditableDetailRow = memo(EditableDetailRowNoMemo);
  const Agent = memo(AgentNoMemo);
  const AgentMultiple = memo(AgentMultipleNoMemo);
  const apiService = new AxiosService();
  const authDetails = LocalStorageService.getAuthDetails();

  const [show2, setShow2] = useState(true);
  const [show5, setShow5] = useState(true);
  const [show9, setShow9] = useState(true);
  const [show10, setShow10] = useState(true);
  const [show27, setShow27] = useState(true);
  const [loader, setLoader] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const [savedComments, setComments] = useState([]);
  const [commentsLoader, setCommentsLoader] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const [dfuData, setDfuData] = useState([]);
  const [modifiedData, setModifiedData] = useState("");
  const [childData, setChildData] = useState(data);
  const [expandAll, setExpandAll] = useState(true);

  const tableRef = useRef(null);

  const sectionRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  const JumpComments = useRef(null);
  const JumpValidationComment = useRef(null);
  const JumpCvAdditionComment = useRef(null);
  const JumpEditedReport = useRef(null);

  // const activeSection = useScrollSpy({
  //   sectionElementRefs: sectionRefs,
  //   offsetPx: -60,
  // });

  const jumpTo = (ref) => {
    if (!ref) return;

    ref.current.scrollIntoView();
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      // Call updateElements when mutations are observed
      methodToCallAfterLoad();
    });
    observer.observe(document.body, {
      childList: true, // Observe direct children
      subtree: true, // Observe all descendants
    });

    methodToCallAfterLoad();

    return () => observer.disconnect();
  }, []);

  // useEffect(() => {

  //   setTimeout(() => {
  //     methodToCallAfterLoad();
  //   }, 1200);
  // } ,[show5])

  const methodToCallAfterLoad = () => {
    requestAnimationFrame(() => {

      const elements = document.querySelectorAll(".enableEdit");

      elements.forEach((element) => {
        if (editFlag === true) {
          // Check if the parent div already exists to prevent duplicate elements
          if (!element.querySelector(".parent-div")) {
            // Step 1: Create the parent div that will contain the content and the icon
            const parentDiv = document.createElement("div");
            parentDiv.className = "parent-div textarea-like";  // Optional class for styling
            parentDiv.style.display = "inline-flex"; 
            parentDiv.style.width = "100%";
      
            // Step 2: Create the first div for the editable content
            const contentDiv = document.createElement("div");
            contentDiv.className = "content-div";  // Optional class for styling
            contentDiv.innerText = element.innerText;  // Move the original content to this div
      
            // Step 3: Make the content editable and set its attributes
            contentDiv.setAttribute("contenteditable", "true");
            contentDiv.setAttribute("id" ,element.getAttribute("id"));
            contentDiv.setAttribute("name", element.innerText);  // Set the name or initial content
            contentDiv.setAttribute("tabindex", "0");  // Set tabindex for focus navigation
            contentDiv.style.width = "98%";
      
            // Step 4: Create the div for the edit icon
            const editIconDiv = document.createElement("div");
            editIconDiv.className = "editIcon";
            editIconDiv.title = "Edit";  // Set title for the icon
            const icon = document.createElement("i");
            icon.className = "fas fa-edit";  // FontAwesome class for the edit icon
            icon.style.fontSize = "0.75rem";  // Optional size styling for the icon
            editIconDiv.appendChild(icon);  // Append the icon to the icon div
      
            // Step 5: Append the content div and the icon div to the parent div
            parentDiv.appendChild(contentDiv);
            parentDiv.appendChild(editIconDiv);
      
            // Step 6: Replace the original element with the new parent div
            element.removeAttribute("id");
            element.innerText="";
            element.appendChild(parentDiv);
      
            // Step 7: Add the click event to the icon to focus the content div
            editIconDiv.addEventListener("click", () => {
              contentDiv.focus(); 
              const range = document.createRange();
              const selection = window.getSelection();

              range.selectNodeContents(contentDiv);
              range.collapse(false);
              selection.removeAllRanges();
              selection.addRange(range);

            });
      
            // Step 8: Add a blur event listener to handle when the content div loses focus
            contentDiv.addEventListener("blur", handleBlurEdit);
           // contentDiv.addEventListener("click" ,handleOnClickEdit);
          }
      
        } else {
          // In case of no editFlag, just add a class for non-editable elements
          element.classList.add("textarea-like");
        }
      });
      
      
      // const elements = document.querySelectorAll(".enableEdit");

      // elements.forEach((element) => {
      //   if (editFlag === true) {
      //     if (!element.querySelector(".fas.fa-edit")) {
      //       const editIconDiv = document.createElement("div");
      //       editIconDiv.className = "editIcon";
      //       editIconDiv.title = "Edit";
      //       // Create a new FontAwesome icon element
      //       const icon = document.createElement("i");
      //       icon.className = "fas fa-edit"; // FontAwesome class for the edit icon
      //       icon.style.fontSize = "0.75rem"; // Equivalent to size="xs"

      //       // Append the icon to the div
      //       editIconDiv.appendChild(icon);
      //       element.appendChild(editIconDiv);
      //     }
      //     let content = document.getElementById(element.id);
      //     console.log();
      //     element.setAttribute("contenteditable", "true");
      //     element.setAttribute("name", content ? content.innerText : "");
      //     element.classList.add("textarea-like");
      //     element.setAttribute("tabindex", "0");
      //     //  element.addEventListener('click', handleOnClickEdit);
      //     element.addEventListener("blur", handleBlurEdit);
      //   } else {
      //     element.classList.add("textarea-like");
      //   }
      // });
    });
  };

  const logInduvidualDataChanges = (id, content) => {
    let oldId = id;
    try {
      if (id) {
        const query = `$..${id}`;
        const found = jp.paths(data, query);
        console.log("found path  :", found);
        id = found.length > 0 ? found[0].join(".") : "";
      }
    } catch (error) {
      console.error(error);
    }
    const authDetailsParsed = JSON.parse(authDetails);

    const saveData = {
      data: content,
      oldData: "",
    };

    apiService
      .LogIndividualData(id, saveData, label, authDetailsParsed.username)
      .then(async (response) => {
        console.log(response.data);

        if (response.data === "saved") {
          toast.success("Saved!");
          window.dispatchEvent(new Event("sessionFlagUpdated"));
          await updateValueAtCustomPath(data, id, content);
        } else if (response.data !== "noChange") {
          setAlertMessage(response.data);
          setShowAlert(true);
          const oldValue = await getValueFromPath(data, id);
          document.getElementById(oldId).innerText = oldValue;
        }

        //setModifiedData(content);
        // document.getElementById(id).innerText =content;
      })
      .catch((error) => {
        console.log(error);
        //  alert(error);
      });
  };
  async function updateValueAtCustomPath(data, path, newValue) {
    const cleanedPath = path.replace(/^\$\./, "").replace(/\.\@none$/, "");

    const segments = cleanedPath.split(".");
    // Traverse the object to the second-to-last segment
    let current = data;
    for (let i = 0; i < segments.length - 1; i++) {
      const segment = segments[i];
      if (current[segment] === undefined) {
        // If the segment does not exist, initialize it as an empty object
        current[segment] = {};
      }
      current = current[segment];
    }

    // Update the value at the last segment
    const lastSegment = segments[segments.length - 1];
    // console.log(lastSegment)
    // const isSpecialCase = path.endsWith('@none');

    // let oldValue;
    // if (isSpecialCase) {
    //   oldValue = current['@none'];
    //   console.log('Old Value:', oldValue);
    // } else {
    //   oldValue = current[lastSegment];
    // }

    // console.log('Old Value:', oldValue);

    if (path.endsWith("@none")) {
      // Handle the special @none case
      current["@none"] = newValue;
    } else {
      current[lastSegment] = newValue;
    }

    console.log("New Value:", newValue);

    // setChildData(current);
    // onDataChange(childData);

    data = current;
  }

  async function getValueFromPath(json, path) {
    const cleanedPath = path.replace(/^\$\./, "").replace(/\.\@none$/, "");

    const keys = cleanedPath.split(/[\.\[\]]/).filter(Boolean); // Split on dots and brackets
    let result = json;

    for (let key of keys) {
      if (result && result[key] !== undefined) {
        result = result[key];
      } else {
        return undefined; // Return undefined if key is not found
      }
    }

    return result;
  }

  useEffect(() => {
    const handleSessionStorageUpdate = () => {
      //data =LocalStorageService.getSelectedLabelData();
    };

    window.addEventListener("sessionFlagUpdated", handleSessionStorageUpdate);

    return () => {
      window.removeEventListener(
        "sessionFlagUpdated",
        handleSessionStorageUpdate
      );
    };
  }, []);

  const handleOnClickEdit = useCallback((event) => {
    console.log(event.target.id)
    console.log(event.target.name)
    const id = event.target.id;
    if (id) {
      const editableDiv = document.getElementById(id);

    if (editableDiv && editableDiv.contentEditable === 'true') {
      editableDiv.focus();

      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(editableDiv);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    }
    }
  }, []);

  const handleBlurEdit = useCallback(
    (event) => {
      // console.log(event.currentTarget.getAttribute('name'))
      // console.log(event.target.name ,"--- ", event.target.innerText );
      //if (event.currentTarget.getAttribute('name')  && event.currentTarget.getAttribute('name')  !== event.target.innerText) {
      let newContent = event.target.innerText;
      const targetElement = event.currentTarget;
      //  const oldContent = targetElement.getAttribute('name');
      let id = event.target.id;
      console.log(" id ", id);
      newContent = newContent.trim();
      event.target.innerText = newContent;

      logInduvidualDataChanges(id, newContent);
      // }
    },
    [modifiedData]
  );

  const getComments = () => {
    setCommentsLoader(true);

    apiService
      .getComments(label)
      .then((response) => {
        setCommentsLoader(false);
        if (response.data) {
          setComments(response.data);
        }
      })
      .catch((error) => {
        setCommentsLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  const saveComment = (commentData, secNo) => {
    setSaveLoader(true);

    const saveData = {
      data: { sectionNo: secNo, comment: commentData },
    };
    const auth_details = JSON.parse(authDetails);

    apiService
      .saveComment(saveData, label, auth_details.username)
      .then((response) => {
        setSaveLoader(false);
        if (response.data) {
          toast.success("Comment saved successfully!");
          getComments();
        }
      })
      .catch((error) => {
        setSaveLoader(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  useEffect(() => {
    if (label) {
      getComments();
    }
  }, [label]);

  const loadComments = (flag) => {
    if (flag === true) {
      getComments();
    }
  };

  useEffect(() => {
    // if(show5){
    //   //getDirectionForUseData();
    // }
  }, [show5]);

  const getDirectionForUseData = () => {
    setLoader(true);
    apiService
      .getDirectionForUseData(label)
      .then((response) => {
        if (response.data) {
          setDfuData(response.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      });
  };

  useEffect(() => {}, [dfuData]);

  const [showCommentTree, setShowCommentTree] = useState(true);

  const toggleComponents = () => {
    setShowCommentTree(!showCommentTree);
  };

  const handleShowAllComment = (sectionNo) => {
    // if (
    //   window.confirm(
    //     `Do you want to show all the comments?`
    //   )
    // ) {
      apiService
        .showAllComment(label ,sectionNo)
        .then((response) => {
          console.log(response)
          if (response.data) {
            if(response.data ==="nothingToShow"){
              toast.info("There are no hidden comments.");
            }else{
              getComments();
              toast.success("All comments are shown successfully!");
            }
            
          }
        })
        .catch((error) => {
          console.log(error)

          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong!");
          }
        });
    //}
  };

  const handleExpandAll =() =>{
    setExpandAll(!expandAll);
  }


  return (
    <>
      <Sidebar
        data={data}
        sectionRefs={sectionRefs}
        commentRef={JumpComments}
        // activeSection={}
        jumpTo={jumpTo}
        validationCommentRef={JumpValidationComment}
        editedReportRef={JumpEditedReport}
        cvAdditionCommentRef={JumpCvAdditionComment}
      />

      {/* <DownloadTableExcel
        filename="users table"
        sheet="users"
        className="btn btn-light"
        currentTableRef={tableRef.current}
      >
        <button> Export excel </button>
      </DownloadTableExcel> */}
      <div class="table-responsive my-2">
        <table ref={tableRef} class="table table-bordered" id="mainTable">
          <thead>
            <tr className="table-secondary ">
              <th style={{ width: "7%" }} scope="col">
                #
              </th>
              <th style={{ width: "20%" }} scope="col">
                Property
              </th>
              <th style={{ width: "70%" }} scope="col">
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {data.tradeName && (
              <tr ref={sectionRefs[0]}>
                <th scope="row">1</th>

                <td className="table-light">
                  Trade Name{" "}
                  <LabelWithInfoTooltip
                    info={"The trade name of the product"}
                    id="tradeName"
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"1"}
                  />
                  <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"1"}
                  />
                </td>
                <td>
                  <TableCell
                    enableEdit={true}
                    editFlag={editFlag}
                    data={data.tradeName["@none"]}
                    idField={`tradeName["@none"]`}
                  />
                </td>
              </tr>
            )}

            {data.regulatoryAuthorisation && (
              <>
                <tr ref={sectionRefs[1]}>
                  <th scope="row">
                    2
                    <ExpandToggle
                      boolToggle={show2}
                      onToggle={(e) => setShow2(!show2)}
                    />
                  </th>

                  <td className="table-light">
                    Regulatory Authorisation{" "}
                    <LabelWithInfoTooltip
                      info={
                        "Regulatory authorisation information for the product"
                      }
                      id="regulatoryAuthorisation"
                    />
                    <ShowSectioncomment
                      handleShowAllComment={handleShowAllComment}
                      slNo={"2"}
                    />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"2"}
                    />
                  </td>

                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.regulatoryAuthorisation.label["@none"]}
                      idField={`regulatoryAuthorisation.label["@none"]`}
                    />

                    <DetailRow
                      // enableEdit={true}
                      //  editFlag={editFlag}
                      label={"Valid From"}
                      data={data.regulatoryAuthorisation.validFrom}
                       idField={`regulatoryAuthorisation.validFrom`}
                      tooltipId={"validFrom"}
                      tooltipInfo={
                        "The date from which the formulation authorisation is valid"
                      }
                    />
                    <DetailRow
                      // enableEdit={true}
                      // editFlag={editFlag}
                      label={"Valid To"}
                      data={data.regulatoryAuthorisation.validTo}
                       idField={`regulatoryAuthorisation.validTo`}
                      tooltipId={"validTo"}
                      tooltipInfo={
                        "The date up until which the formulation authorisation is valid"
                      }
                    />
                    <DetailRow
                      // enableEdit={true}
                      // editFlag={editFlag}
                      label={"Last Authorised Selling Date"}
                      data={
                        data.regulatoryAuthorisation.lastAuthorisedSellingDate
                      }
                      idField={`regulatoryAuthorisation.lastAuthorisedSellingDate`}
                    />
                    <DetailRow
                      //enableEdit={true}
                      // editFlag={editFlag}
                      label={"Last Authorised Usage Date"}
                      data={
                        data.regulatoryAuthorisation.lastAuthorisedUsageDate
                      }
                       idField={`regulatoryAuthorisation.lastAuthorisedUsageDate`}
                    />
                  </td>
                </tr>

                {show2 && (
                  <>
                    {data.regulatoryAuthorisation.packagingContact && (
                      <tr>
                        <th scope="row">2.1</th>
                        <td className="table-light">
                          Packaging Contact
                          <LabelWithInfoTooltip
                            id={"packagingContact"}
                            info={
                              "The contact responsible for product packaging, if different from the authorisation holder. As per EU Directive No 547/2011 Annex 1 section 1b, 'if different, the name and address of the person responsible for the final packaging and labelling or for the final labelling of the plant protection product on the market'"
                            }
                          />
                          <ShowSectioncomment
                            handleShowAllComment={handleShowAllComment}
                            slNo={"2.1"}
                          />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.1"}
                          />
                        </td>
                        <td>
                          {data.regulatoryAuthorisation.packagingContact &&
                          Array.isArray(
                            data.regulatoryAuthorisation.packagingContact
                          ) ? (
                            <Suspense fallback={<div> Loading ...</div>}>
                              {" "}
                              <AgentMultiple
                                data={
                                  data.regulatoryAuthorisation.packagingContact
                                }
                                idField={`regulatoryAuthorisation.packagingContact`}
                              />
                            </Suspense>
                          ) : (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <Agent
                                data={
                                  data.regulatoryAuthorisation.packagingContact
                                }
                                idField={`regulatoryAuthorisation.packagingContact`}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.labellingContact && (
                      <tr>
                        <th scope="row">2.2</th>
                        <td className="table-light">
                          Labelling Contact
                          <LabelWithInfoTooltip
                            id={"labellingContact"}
                            info={
                              "The contact responsible for product labelling, if different from the authorisation holder. As per EU Directive No 547/2011 Annex 1 section 1b, 'if different, the name and address of the person responsible for the final packaging and labelling or for the final labelling of the plant protection product on the market'"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.2"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.2"}
                          />
                        </td>
                        <td>
                          {data.regulatoryAuthorisation.labellingContact &&
                          Array.isArray(
                            data.regulatoryAuthorisation.labellingContact
                          ) ? (
                            <Suspense fallback={<div> Loading ...</div>}>
                              {" "}
                              <AgentMultiple
                                data={
                                  data.regulatoryAuthorisation.labellingContact
                                }
                                idField={`regulatoryAuthorisation.labellingContact`}
                              />
                            </Suspense>
                          ) : (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <Agent
                                data={
                                  data.regulatoryAuthorisation.labellingContact
                                }
                                idField={`regulatoryAuthorisation.labellingContact`}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.authorisationHolder && (
                      <tr>
                        <th scope="row">2.3</th>
                        <td className="table-light">
                          Authorisation Holder
                          <LabelWithInfoTooltip
                            id={"authorisationHolder"}
                            info={
                              "The person or organisation authorised to sell the registered formulation. As per EU Directive No 547/2011 Annex 1 section 1b, 'the name and address of the holder of the authorisation'"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.3"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.3"}
                          />
                        </td>
                        <td>
                          {data.regulatoryAuthorisation.authorisationHolder &&
                          Array.isArray(
                            data.regulatoryAuthorisation.authorisationHolder
                          ) ? (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <AgentMultiple
                                data={
                                  data.regulatoryAuthorisation
                                    .authorisationHolder
                                }
                                idField={`regulatoryAuthorisation.authorisationHolder`}
                              />
                            </Suspense>
                          ) : (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <Agent
                                data={
                                  data.regulatoryAuthorisation
                                    .authorisationHolder
                                }
                                idField={`regulatoryAuthorisation.authorisationHolder`}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.permittedUse && (
                      <tr>
                        <th scope="row">2.4</th>
                        <td>
                          Permitted Use
                          <LabelWithInfoTooltip
                            id={"permittedUse"}
                            info={"The authorised uses of the product"}
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.4"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.4"}
                          />
                        </td>
                        <td>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={
                              data.regulatoryAuthorisation.permittedUse["@none"]
                            }
                            idField={`regulatoryAuthorisation.permittedUse["@none"]`}
                          />
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.permittedUserCategories && (
                      <tr>
                        <th scope="row">2.5</th>
                        <td>
                          Permitted User Categories
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.5"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.5"}
                          />
                        </td>
                        <td>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={
                              data.regulatoryAuthorisation
                                .permittedUserCategories["@none"]
                            }
                            idField={`regulatoryAuthorisation.permittedUserCategories["@none"]`}
                          />
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.prohibitedUse && (
                      <tr>
                        <th scope="row">2.6</th>
                        <td>
                          Prohibited Use
                          <LabelWithInfoTooltip
                            id={"prohibitedUse"}
                            info={
                              "The restrictions and explicitly prohibited uses of the product or its packagings"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.6"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.6"}
                          />
                        </td>
                        <td>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={
                              data.regulatoryAuthorisation.prohibitedUse[
                                "@none"
                              ]
                            }
                            idField={`regulatoryAuthorisation.prohibitedUse["@none"]`}
                          />
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.regulator && (
                      <tr>
                        <th scope="row">2.7</th>
                        <td className="table-light">
                          Regulator
                          <LabelWithInfoTooltip
                            id={"regulator"}
                            info={
                              "The regulatory body which issued the authorisation"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.7"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.7"}
                          />
                        </td>
                        <td>
                          <Suspense fallback={<div> Loading ...</div>}>
                            <Agent
                              data={data.regulatoryAuthorisation.regulator}
                              idField={`regulatoryAuthorisation.regulator`}
                            />
                          </Suspense>
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.authorisationNo && (
                      <>
                        <tr>
                          <th scope="row">2.8</th>
                          <td>
                            Authorisation Number
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.8"}
                  />
                            <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"2.8"}
                            />
                          </td>
                          <td>
                            <AgentRow
                              data={
                                data.regulatoryAuthorisation.authorisationNo
                              }
                              idField={`regulatoryAuthorisation.authorisationNo`}
                            />
                          </td>
                        </tr>
                      </>
                    )}
                    {data.regulatoryAuthorisation.authorisationNumber && (
                      <>
                        <tr>
                          <th scope="row">2.8</th>
                          <td>
                            Authorisation Number
                            <LabelWithInfoTooltip
                              id={"authorisationNumber"}
                              info={
                                "The authorisation number of the registered formulation"
                              }
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.8"}
                  />
                            <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"2.8"}
                            />
                          </td>
                          <td>
                            <AgentRow
                              data={
                                data.regulatoryAuthorisation.authorisationNumber
                              }
                              idField={`regulatoryAuthorisation.authorisationNumber`}
                            />
                          </td>
                        </tr>
                      </>
                    )}

                    {data.regulatoryAuthorisation
                      .authorisationNoVersionHistory && (
                      <>
                        <tr>
                          <th scope="row">2.9</th>
                          <td>
                            Authorisation No Version History
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.9"}
                  />
                            <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"2.9"}
                            />
                          </td>
                          <td>
                            {data.regulatoryAuthorisation.authorisationNoVersionHistory.map(
                              (each, j) => (
                                <AgentRow data={each}  idField={`regulatoryAuthorisation.authorisationNoVersionHistory[${j}]`} />
                              )
                            )}
                          </td>
                        </tr>
                      </>
                    )}

                    {data.regulatoryAuthorisation.localRepresentative && (
                      <tr>
                        <th scope="row">2.10</th>
                        <td className="table-light">
                          Local Representative
                          <LabelWithInfoTooltip
                            id={"localRepresentative"}
                            info={
                              "A local legal entity representing the authorisation holder if the authorisation holder is registered in another territorys"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.10"}
                  />
                          <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"2.10"}
                          />
                        </td>
                        <td>
                          {data.regulatoryAuthorisation.localRepresentative &&
                          Array.isArray(
                            data.regulatoryAuthorisation.localRepresentative
                          ) ? (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <AgentMultiple
                                data={
                                  data.regulatoryAuthorisation
                                    .localRepresentative
                                }
                                idField={`regulatoryAuthorisation.localRepresentative`}
                              />
                            </Suspense>
                          ) : (
                            <Suspense fallback={<div> Loading ...</div>}>
                              <Agent
                                data={
                                  data.regulatoryAuthorisation
                                    .localRepresentative
                                }
                                idField={`regulatoryAuthorisation.localRepresentative`}
                              />
                            </Suspense>
                          )}
                        </td>
                      </tr>
                    )}

                    {data.regulatoryAuthorisation.statutoryContext && (
                      <>
                        <tr>
                          <th scope="row">2.11</th>
                          <td>
                            Statutory Context
                            <LabelWithInfoTooltip
                              id={"statutoryContext"}
                              info={
                                "The legal statutes or decrees under which the authorisation has been issued"
                              }
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.11"}
                  />
                            <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"2.11"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.regulatoryAuthorisation.statutoryContext.map(
                                (each, i) => (
                                  <li>
                                    <TableCell
                                      enableEdit={true}
                                      editFlag={editFlag}
                                      data={each["@none"]}
                                      idField={`regulatoryAuthorisation.statutoryContext[${i}]["@none"]`}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}
                    {data.regulatoryAuthorisation.disclaimer && (
                      <>
                        <tr>
                          <th scope="row">2.12</th>
                          <td>
                            <span className="bg-warning"> Disclaimer</span>
                            <LabelWithInfoTooltip
                              id={"disclaimer"}
                              info={"Any disclaimer stated on the label"}
                            />
                             <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"2.12"}
                  />
                            <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"2.12"}
                            />
                          </td>
                          <td>
                            {/* <ul>
                              {data.regulatoryAuthorisation.disclaimer.map(
                                (each, i) => (
                                  <li> */}
                            <TableCell
                              enableEdit={true}
                              editFlag={editFlag}
                              data={
                                data.regulatoryAuthorisation.disclaimer["@none"]
                              }
                              idField={`regulatoryAuthorisation.disclaimer["@none"]`}
                            />
                            {/* </li>
                                )
                              )}
                            </ul> */}
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                )}
              </>
            )}

            {data.territory && (
              <tr ref={sectionRefs[2]}>
                <th scope="row">3</th>
                <td className="table-light">
                  Territory
                  <LabelWithInfoTooltip
                    id={"territory"}
                    info={
                      "The country or territory in which the product is authorised"
                    }
                  />
                     <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"3"}
                  />
                  <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"3"}
                  />
                </td>
                <td>
                  <TableCell data={data.territory.label["@none"]}  idField={`territory.label["@none"]`}/>
                  <DetailRow
                    label={"Country Code"}
                    data={data.territory.countryCode}
                    idField={`territory.countryCode`}
                  />
                </td>
              </tr>
            )}

            {data.formulationIngredients && (
              <>
                <tr ref={sectionRefs[3]}>
                  <th scope="row">4</th>
                  <td className="table-light">
                    Formulation Ingredients
                    <LabelWithInfoTooltip
                      id={"formulationIngredients"}
                      info={
                        "The active substances, synergists and safeners which are included in the product"
                      }
                    />
                       <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"4"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"4"}
                    />
                  </td>
                  <td>
                    <div className="table-responsive">
                      <table class="table  mt-2  table-bordered mb-0">
                        <thead>
                          <tr className="table-light">
                            <th scope="col">#</th>
                            <th scope="col" className="w-25">
                              Label
                            </th>
                            <th scope="col">
                              Substance
                              <LabelWithInfoTooltip
                                id={"substance"}
                                info={
                                  "A substance that is used as an ingredient in the product formulation. As per EU Directive No 547/2011 Annex 1 section 1c ' The name must be as given in the list contained in Annex VI to Regulation (EC) No 1272/2008 of the European Parliament and of the Council (2) or, if not included therein, its ISO common name. If the latter is not available, the active substance shall be designated by its chemical designation according to IUPAC rules'"
                                }
                              />
                            </th>
                            <th scope="col">
                              Substance Role
                              <LabelWithInfoTooltip
                                id={"substanceRole"}
                                info={
                                  "The role performed by the substance in the formulation (e.g. active substance, synergist, safener) - todo: review for controlled vocab"
                                }
                              />
                            </th>
                            <th scope="col">
                              Concentration
                              <LabelWithInfoTooltip
                                id={"concentration"}
                                info={
                                  "The concentration of an ingredient used in the product formulation"
                                }
                              />
                            </th>
                            <th scope="col">
                              <span className="bg-warning"> Is Pure</span>
                              <LabelWithInfoTooltip
                                id={"isPure"}
                                info={
                                  "Flags whether the ingredient is of a high purity grade"
                                }
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.formulationIngredients.map((each, i) => (
                            <tr>
                              <th scope="row">{i + 1}</th>
                              <td className="w-25">
                                <TableCell
                                  enableEdit={true}
                                  editFlag={editFlag}
                                  data={each.label["@none"]}
                                  idField={`formulationIngredients[${i}].label["@none"]`}
                                />
                              </td>
                              <td>
                                {each.substance ? (
                                  <>
                                    <TableCell
                                      data={each.substance.label["@none"]}
                                      idField={`formulationIngredients[${i}].substance.label["@none"]`}
                                    />
                                    {each.substance.casRegistryNo && (
                                      <DetailRow
                                        label={"CAS Registry No"}
                                        data={each.substance.casRegistryNo}
                                        tooltipId={"formulation-casRegistryNo"}
                                        tooltipInfo={
                                          "The CAS Registry No of the substance, to used where an EINECS number is not availabel - see Annex VI Regulation (EC) No 1271/2008"
                                        }
                                        idField={`formulationIngredients[${i}].substance.casRegistryNo`}
                                      />
                                    )}
                                    {each.substance?.modeOfAction && (
                                      <>
                                        <div>
                                          <span className="fw-bold">
                                            Mode of Action:
                                          </span>
                                          <LabelWithInfoTooltip
                                            id={"formulation-modeOfAction"}
                                            info={
                                              "The XRAC mode of action classification for the substance"
                                            }
                                          />
                                        </div>
                                        {each.substance.modeOfAction?.map(
                                          (each1, j) => (
                                            <>
                                              <TableCell data={each1.label["@none"]} idField={`formulationIngredients[${i}].substance.modeOfAction[${j}].label["@none"]`} />
                                              {each1.modeOfActionScheme && (
                                                <DetailRow
                                                  label={
                                                    "Mode Of Action Scheme"
                                                  }
                                                  data={
                                                    each1.modeOfActionScheme
                                                      .label["@none"]
                                                  }
                                                  tooltipId={
                                                    "formulation-modeOfActionScheme"
                                                  }
                                                  tooltipInfo={
                                                    "The encoding scheme to which the XRAC code belongs, i.e. current or legacy classifications"
                                                  }
                                                  idField={`formulationIngredients[${i}].substance.modeOfAction[${j}].modeOfActionScheme.label["@none"]`}
                                                />
                                              )}
                                            </>
                                          )
                                        )}
                                      </>
                                    )}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {each.substanceRole ? (
                                  <TableCell
                                    data={each.substanceRole.label["@none"]}
                                    idField={`formulationIngredients[${i}].substanceRole.label["@none"]`}
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {each.concentration &&
                                  each.concentration.map((each2, j) => (
                                    <>
                                      {each2.label && (
                                        <DetailRow
                                          enableEdit={true}
                                          editFlag={editFlag}
                                          label={"label"}
                                          data={each2.label["@none"]}
                                          idField={`formulationIngredients[${i}].concentration[${j}].label["@none"]`}
                                        />
                                      )}

                                      <DetailRow
                                        enableEdit={true}
                                        editFlag={editFlag}
                                        label={"Value"}
                                        data={each2.value}
                                        idField={`formulationIngredients[${i}].concentration[${j}].value`}
                                      />

                                      <DetailRow
                                        label={"Min"}
                                        data={each2.min}
                                        enableEdit={true}
                                        editFlag={editFlag}
                                        idField={`formulationIngredients[${i}].concentration[${j}].min`}
                                      />

                                      <DetailRow
                                        label={"Max"}
                                        data={each2.max}
                                        idField={`formulationIngredients[${i}].concentration[${j}].max`}
                                        enableEdit={true}
                                        editFlag={editFlag}
                                      />

                                      <DetailRow
                                        label={"Less Than"}
                                        data={each2.lessThan}
                                        idField={`formulationIngredients[${i}].concentration[${j}].lessThan`}
                                        enableEdit={true}
                                        editFlag={editFlag}
                                      />

                                      <DetailRow
                                        label={"Greater Than"}
                                        data={each2.greaterThan}
                                        idField={`formulationIngredients[${i}].concentration[${j}].greaterThan`}
                                        enableEdit={true}
                                        editFlag={editFlag}
                                      />
                                      <DetailRow
                                        label={"Qualifier"}
                                        data={each2.qualifier}
                                        enableEdit={true}
                                        editFlag={editFlag}
                                        idField={`formulationIngredients[${i}].concentration[${j}].qualifier`}
                                        tooltipId={"qualifier"}
                                        tooltipInfo={
                                          "Any conditional qualifier associated with the quantity"
                                        }
                                      />

                                      {each2.unitOfMeasurement &&
                                        each2.unitOfMeasurement?.label && (
                                          <DetailRow
                                            label={"Unit Of Measurement"}
                                            data={
                                              each2.unitOfMeasurement.label[
                                                "@none"
                                              ]
                                            }
                                            idField={`formulationIngredients[${i}].concentration[${j}].unitOfMeasurement.label["@none"]`}
                                          />
                                        )}
                                      {each2.unitOfMeasurement &&
                                        each2.unitOfMeasurement.symbol && (
                                          <DetailRow
                                            label={"Unit Of Measurement"}
                                            data={
                                              each2.unitOfMeasurement.symbol[
                                                "@none"
                                              ]
                                            }
                                            idField={`formulationIngredients[${i}].concentration[${j}].unitOfMeasurement.symbol["@none"]`}
                                          />
                                        )}
                                      {each2.unitOfMeasurement &&
                                        each2.unitOfMeasurement
                                          .unitLongName && (
                                          <DetailRow
                                            label={"Unit Long Name"}
                                            data={
                                              each2.unitOfMeasurement
                                                .unitLongName
                                            }
                                            highlight={true}
                                            tooltipId={
                                              "concentration-unitLongName"
                                            }
                                            tooltipInfo={
                                              "The long name of the unit of measurement"
                                            }
                                            idField={`formulationIngredients[${i}].concentration[${j}].unitOfMeasurement.unitLongName`}
                                          />
                                        )}
                                    </>
                                  ))}
                              </td>
                              <td>
                                {each.isPure && (
                                  <TableCell
                                    //enableEdit={true}
                                    // editFlag={editFlag}
                                    data={each.isPure.toString()}
                                    idField={`formulationIngredients[${i}].isPure`}
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </>
            )}

            {data.directionsForUse && (
              <>
                <tr ref={sectionRefs[4]}>
                  <th scope="row">
                    5
                     <ExpandToggle
                      boolToggle={expandAll}
                      onToggle={(e) => setExpandAll(!expandAll)}
                    /> 
                  </th>
                  <td className="table-light">
                    Directions For Use
                    <LabelWithInfoTooltip
                      id={"directionsForUse"}
                      info={"Usage directions for the registered formulation"}
                    />
                       <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"5"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"5"}
                    />
                  </td>
                  <td></td>
                </tr>

                {show5 && (
                  // dfuData && dfuData.length >0 &&
                  <DirectionForUseParentElement
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    saveComment={saveComment}
                    //directionData={dfuData}
                    loadComments={loadComments}
                    editFlag={editFlag}
                    labelId={label}
                    handleShowAllComment={handleShowAllComment}
                    expandAll={expandAll}
                    handleExpandAll ={handleExpandAll}
                  />
                )}
              </>
            )}

            {data.directionsForStorage && (
              <>
                <tr ref={sectionRefs[5]}>
                  <th scope="row">6</th>
                  <td className="table-light">
                    Directions For Storage
                    <LabelWithInfoTooltip
                      id={"directionsForStorage"}
                      info={"Guidance on how the product should be stored"}
                    />
                       <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"6"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"6"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.directionsForStorage.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`directionsForStorage[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.directionsForDisposal && (
              <>
                <tr ref={sectionRefs[6]}>
                  <th scope="row">7</th>
                  <td className="table-light">
                    Directions For Disposal
                    <LabelWithInfoTooltip
                      id={"directionsForDisposal"}
                      info={
                        "Guidance concerning the safe disposal of the product"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"7"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"7"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.directionsForDisposal.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`directionsForDisposal[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.adverseEffects && (
              <tr ref={sectionRefs[7]}>
                <th scope="row">8</th>
                <td className="table-light">
                  Adverse Effects
                  <LabelWithInfoTooltip
                    id={"adverseEffects"}
                    info={
                      "The identified adverse effects of the registered formulation"
                    }
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"8"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"8"}
                  />
                </td>
                <td>
                  <ul>
                    {data.adverseEffects.map((each, i) => (
                      <li>
                        <DetailRow
                          enableEdit={true}
                          editFlag={editFlag}
                          label={"Label"}
                          data={each.label["@none"]}
                          idField={`adverseEffects[${i}].label["@none"]`}
                        />

                        {each.adverseEffectDescription && (
                          <DetailRow
                            enableEdit={true}
                            editFlag={editFlag}
                            label={"Adverse Effect Description"}
                            data={each.adverseEffectDescription["@none"]}
                            idField={`adverseEffects[${i}].adverseEffectDescription["@none"]`}
                            tooltipId={"adverseEffectDescription"}
                            tooltipInfo={
                              "The description and guidance relating to an adverse effect (e.g. phytotoxicity)"
                            }
                          />
                        )}

                        {each.adverseEffectType && (
                          <DetailRow
                            label={"Adverse Effect Type"}
                            data={each.adverseEffectType.label["@none"]}
                            tooltipId={"adverseEffectType"}
                            tooltipInfo={
                              "The type of adverse effect, most typically phytotoxicity or varietal susceptibility"
                            }
                            idField={`adverseEffects[${i}].adverseEffectType`}
                          />
                        )}

                        {each.impactedCrop && (
                          <>
                            <div>
                              <span className="fw-bold">Impacted Crop:</span>
                              <LabelWithInfoTooltip
                                id={"impactedCrop"}
                                info={
                                  "The crop impacted by the specified adverse effects"
                                }
                              />
                            </div>

                            <div className="table-responsive">
                              {each.impactedCrop.map((each2, i) => (
                                <table class="table  mt-2  table-bordered mb-0">
                                  <tbody>
                                    {/* <tr className="table-light">
                                  <th scope="col">#</th>
                                  <td scope="row">{j + 1}</td>
                                  </tr> */}
                                    <tr className="table-light">
                                      <td rowSpan="11" style={{ width: "10%" }}>
                                        {i + 1}
                                      </td>
                                      <th scope="col">Label</th>
                                      <td>
                                        <TableCell
                                          enableEdit={true}
                                          editFlag={editFlag}
                                          data={each2.label["@none"]}
                                          idField={`impactedCrop[${i}].label["@none"]`}
                                        />
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">Type</th>
                                      <td>
                                        <TableCell data={each2.type}  idField={`impactedCrop[${i}].type`}/>
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Growth Stage
                                        <LabelWithInfoTooltip
                                          info={"The crop growth stage"}
                                          id={"growthStage"}
                                        />
                                      </th>
                                      <td>
                                        {each2.growthStage &&
                                        each2.growthStage.label ? (
                                          <TableCell
                                            data={
                                              each2.growthStage.label["@none"]
                                            }
                                            idField={`impactedCrop[${i}].growthStage.label["@none"]`}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Crop
                                        <LabelWithInfoTooltip
                                          info={
                                            "The crop with which the event is associated"
                                          }
                                          id={"impacted-crop"}
                                        />
                                      </th>
                                      <td>
                                        {each2.crop &&
                                          each2.crop.map((each3, j) => (
                                            <TableCell
                                              data={
                                                each3.label &&
                                                each3.label["@none"]
                                              }
                                              idField={`impactedCrop[${i}].crop[${j}].label["@none"]`}
                                            />
                                          ))}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Min Growth Stage
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-minGrowthStage"}
                                          info={
                                            "The minimum BBCH growth stage of the crop, where expressed as a range"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.minGrowthStage &&
                                        each2.minGrowthStage.label ? (
                                          <TableCell
                                            data={
                                              each2.minGrowthStage.label[
                                                "@none"
                                              ]
                                            }
                                            idField={`impactedCrop[${i}].minGrowthStage.label["@none"]`}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Max Growth Stage
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-maxGrowthStage"}
                                          info={
                                            "The maximum BBCH growth stage of the crop, where expressed as a range"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.maxGrowthStage &&
                                        each2.maxGrowthStage.label ? (
                                          <TableCell
                                            data={
                                              each2.maxGrowthStage.label[
                                                "@none"
                                              ]
                                            }
                                            idField={`impactedCrop[${i}].minGrowthStage.label["@none"]`}
                                          />
                                        ) : (
                                          "-"
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Cultivation Setting
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-cultivationSetting"}
                                          info={
                                            "The setting or location in which the crop is grown (e.g. indoor, field, polytunnel)"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.cultivationSetting &&
                                          each2.cultivationSetting.map(
                                            (each3, j) => (
                                              <TableCell
                                                data={
                                                  each3.label &&
                                                  each3.label["@none"]
                                                }
                                                idField={`impactedCrop[${i}].cultivationSetting[${j}].label["@none"]`}
                                              />
                                            )
                                          )}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Commodity
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-commodity"}
                                          info={
                                            "The intended commodity for which the crop will be used"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.commodity &&
                                          each2.commodity.map((each3, j) => (
                                            <TableCell
                                              data={
                                                each3.label &&
                                                each3.label["@none"]
                                              }
                                              idField={`impactedCrop[${i}].commodity[${j}].label["@none"]`}
                                            />
                                          ))}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        Crop Label Text
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-cropLabelText"}
                                          info={
                                            "The name of the crop exactly as it appears on the label"
                                          }
                                        />
                                      </th>

                                      <td>
                                        {each2.cropLabelText && (
                                          <TableCell
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            data={each2.cropLabelText["@none"]}
                                            idField={`impactedCrop[${i}].cropLabelText["@none"]`}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="table-light">
                                      <th scope="col">
                                        <span className="bg-warning">
                                          Crop Growth Stage Description
                                        </span>
                                        <LabelWithInfoTooltip
                                          id={
                                            "imactedcrop-cropGrowthStageDescription"
                                          }
                                          info={
                                            "Alternative description of the crop growth stage to be displayed if BBCH description is not satisfactory"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.cropGrowthStageDescription && (
                                          <TableCell
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            data={
                                              each2.cropGrowthStageDescription
                                            }
                                            idField={`impactedCrop[${i}].cropGrowthStageDescription`}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="col">
                                        <span className="bg-warning">
                                          Target Crop Height
                                        </span>
                                        <LabelWithInfoTooltip
                                          id={"imactedcrop-targetCropHeight"}
                                          info={
                                            "The height or height range of the target crop to which the application guidance applies"
                                          }
                                        />
                                      </th>
                                      <td>
                                        {each2.targetCropHeight && (
                                          <Dosage
                                            editFlag={editFlag}
                                            data={each2.targetCropHeight}
                                            idField={`impactedCrop[${i}].targetCropHeight`}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              ))}
                            </div>
                          </>
                        )}
                        {each.cropRelation && (
                          <>
                            <div className="fw-bold">Crop Relation: </div>
                            {each.cropRelation?.map((each1, j) => (
                              <><TableCell data={each1.label["@none"]} idField={`impactedCrop[${i}].cropRelation[${j}].label["@none"]`}/></>
                            ))}
                          </>
                        )}
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            {data.risks && (
              <>
                <tr ref={sectionRefs[8]}>
                  <th scope="row">
                    9
                    <ExpandToggle
                      boolToggle={show9}
                      onToggle={(e) => setShow9(!show9)}
                    />
                  </th>
                  <td className="table-light">
                    Risks
                    <LabelWithInfoTooltip
                      id={"risks"}
                      info={"The product risk assessment and known hazards"}
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"9"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"9"}
                    />
                  </td>
                  <td></td>
                </tr>

                {show9 && (
                  <>
                    {data.risks.label && (
                      <tr>
                        <th scope="row">9.1</th>
                        <td>
                          Label
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"9.1"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"9.1"}
                          />
                        </td>
                        <td>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={data.risks.label["@none"]}
                            idField={`risks.label["@none"]`}
                          />
                        </td>
                      </tr>
                    )}

                    {data.risks.hazardClassifications && (
                      <tr>
                        <th scope="row">9.2</th>
                        <td>
                          Hazard Classification
                          <LabelWithInfoTooltip
                            id={"hazardClassifications"}
                            info={
                              "The GHS hazard classifications of the registered formulation"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"9.2"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"9.2"}
                          />
                        </td>
                        <td>
                          <div className="table-responsive">
                            <table class="table  mt-2   mb-0">
                              <thead>
                                <tr className="table-light">
                                  <th scope="col">#</th>
                                  <th scope="col">Label</th>
                                  <th scope="col">Pictogram</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.risks.hazardClassifications.map(
                                  (each, i) => (
                                    <tr>
                                      <th scope="row">{i + 1}</th>
                                      <td>
                                        <TableCell data={each.label["@none"]} idField={`risks.hazardClassifications.label["@none"]`} />
                                      </td>
                                      <td>
                                        {each.pictogram && (
                                          <img
                                            style={{ maxWidth: "100px" }}
                                            className="img-thumbnail w-25"
                                            src={each.pictogram}
                                            alt="Logo"
                                          />
                                        )}
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}

                    {data.risks.hazardStatements && (
                      <tr>
                        <th scope="row">9.3</th>
                        <td>
                          Hazard Statements
                          <LabelWithInfoTooltip
                            id={"hazardStatements"}
                            info={
                              "The statements of hazard or risk associated with the formulation"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"9.3"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"9.3"}
                          />
                        </td>
                        <td>
                          <div className="table-responsive">
                            <table class="table  mt-2 table-bordered  mb-0">
                              <thead>
                                <tr className="table-light">
                                  <th scope="col">#</th>
                                  <th scope="col" className="w-25">
                                    Label
                                  </th>
                                  <th scope="col">Type</th>
                                  <th scope="col">Text</th>

                                  <th scope="col">Additional Fields</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.risks.hazardStatements.map((each, i) => (
                                  <tr>
                                    <th scope="row">{i + 1}</th>
                                    {each?.label && (
                                      <td className="w-25">
                                        <TableCell
                                          enableEdit={true}
                                          editFlag={editFlag}
                                          data={each?.label["@none"]}
                                          idField={`risks.hazardStatements[${i}].label["@none"]`}
                                        />
                                      </td>
                                    )}
                                    {each?.statement && (
                                      <td className="w-25">
                                        <TableCell
                                          data={each?.statement["@none"]}
                                          idField={`risks.hazardStatements[${i}].statement["@none"]`}
                                        />
                                      </td>
                                    )}
                                    <td>
                                      <TableCell data={each.type}  idField={`risks.hazardStatements[${i}].type`}/>
                                    </td>
                                    <td>
                                      {each.text && (
                                        <TableCell
                                          enableEdit={true}
                                          editFlag={editFlag}
                                          data={each.text["@none"]}
                                          idField={`risks.hazardStatements[${i}].text["@none"]`}
                                        />
                                      )}
                                    </td>
                                    <td>
                                      {each?.regulatoryCode &&
                                        each?.regulatoryCode.map((each2, j) => (
                                          <DetailRow
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            label={"Regulatory Code"}
                                            data={each2}
                                            idField={`risks.hazardStatements[${i}].regulatoryCode[${j}]`}
                                          />
                                        ))}
                                      {each.signalWord && (
                                        <DetailRow
                                          enableEdit={true}
                                          editFlag={editFlag}
                                          label={"Signal Word"}
                                          data={each.signalWord}
                                          idField={`risks.hazardStatements[${i}].signalWord`}
                                          tooltipId={"signalWord"}
                                          tooltipInfo={
                                            "The signal word associated with the hazard classifications"
                                          }
                                        />
                                      )}

                                      {each.placeholderWords &&
                                        each.placeholderWords.map(
                                          (each2, j) => (
                                            <DetailRow
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              label={"Placeholder Words"}
                                              data={each2["@none"]}
                                              idField={`risks.hazardStatements[${i}].placeholderWords[${j}]["@none"]`}
                                            />
                                          )
                                        )}

                                      {each.subjectAtRisk && (
                                        <DetailRow
                                          label={"Subject At Risk"}
                                          data={
                                            each.subjectAtRisk.label["@none"]
                                          }
                                          idField={`risks.hazardStatements[${i}].subjectAtRisk.label["@none"]`}
                                        />
                                      )}
                                      {each.isNote && (
                                        <DetailRow
                                          label={"Is Note"}
                                          data={each.isNote.toString()}
                                          idField={`risks.hazardStatements[${i}].isNote`}
                                        />
                                      )}

                                      {each.referencedHazard &&
                                        each.referencedHazard.code && (
                                          <DetailRow
                                            label={"Referenced Hazard Code"}
                                            data={
                                              each.referencedHazard.code[
                                                "@none"
                                              ]
                                            }
                                            idField={`risks.hazardStatements[${i}].referencedHazard.code["@none"]`}
                                          />
                                        )}

                                      {each.referencedHazard &&
                                        each.referencedHazard.statement && (
                                          <DetailRow
                                            label={
                                              "Referenced Hazard Statement"
                                            }
                                            data={
                                              each.referencedHazard.statement[
                                                "@none"
                                              ]
                                            }
                                            idField={`risks.hazardStatements[${i}].referencedHazard.statement["@none"]`}
                                          />
                                        )}
                                      {each.hazardStatementCategory &&
                                        each.hazardStatementCategory.map(
                                          (each2, j) => (
                                            <DetailRow
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              label={
                                                "Hazard Statement Category"
                                              }
                                              data={each2.label["@none"]}
                                              idField={`risks.hazardStatements[${i}].hazardStatementCategory[${j}].label["@none"]`}
                                              highlight={true}
                                              tooltipId={`hazardStatementCategory-${j}`}
                                              tooltipInfo={
                                                "The category/ies to which a national regulator statement belongs"
                                              }
                                            />
                                          )
                                        )}
                                      {each.precautionCategory &&
                                        each.precautionCategory.map(
                                          (each2, j) => (
                                            <DetailRow
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              label={"Precaution Category"}
                                              data={each2.label["@none"]}
                                              idField={`risks.hazardStatements[${i}].precautionCategory[${j}].label["@none"]`}
                                              highlight={true}
                                              tooltipId={`precautionCategory-${j}`}
                                              tooltipInfo={
                                                "The category/ies to which a national regulator statement belongs"
                                              }
                                            />
                                          )
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </td>
                      </tr>
                    )}

                    {data.risks.signalWord && (
                      <tr>
                        <th scope="row">9.4</th>
                        <td>
                          Signal Word
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"9.4"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"9.4"}
                          />
                        </td>
                        <td>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={data.risks.signalWord["@none"]}
                            idField={`risks.singnalWord["@none"]`}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            )}

            {data.safetyInformation && (
              <>
                <tr ref={sectionRefs[9]}>
                  <th scope="row">
                    10
                    <ExpandToggle
                      boolToggle={show10}
                      onToggle={(e) => setShow10(!show10)}
                    />
                  </th>
                  <td className="table-light">
                    Safety Information
                    <LabelWithInfoTooltip
                      id={"safetyInformation"}
                      info={
                        "The safety information including precautions and medical information related to the safe use of the registered formulation"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"10"}
                    />
                  </td>
                </tr>

                {show10 && (
                  <>
                    <tr>
                      <th scope="row">10.1</th>
                      <td>
                        Label
                        <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.1"}
                  />
                    <Comments
                          savedComments={savedComments}
                          commentsLoader={commentsLoader}
                          saveLoader={saveLoader}
                          onCommentsSave={saveComment}
                          onCommentReloader={loadComments}
                          secNo={"10.1"}
                        />
                      </td>
                      <td>
                        <TableCell
                          enableEdit={true}
                          editFlag={editFlag}
                          data={data.safetyInformation.label["@none"]}
                          idField={`safetyInformation.label["@none"]`}
                        />
                      </td>
                    </tr>

                    {data.safetyInformation.precautions && (
                      <>
                        <tr>
                          <th scope="row">10.2</th>
                          <td>
                            Precautions
                            <LabelWithInfoTooltip
                              id={"precautions"}
                              info={
                                "Precautions relating to the safe usage of the registered formulation"
                              }
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.2"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.2"}
                            />
                          </td>
                          <td>
                            <div className="table-responsive">
                              <table class="table  mt-2 table-bordered  mb-0">
                                <thead>
                                  <tr className="table-light">
                                    <th scope="col">#</th>
                                    <th scope="col" className="w-25">
                                      Label
                                    </th>
                                    <th scope="col" className="w-25">
                                      Type
                                    </th>
                                    <th scope="col">Additional Fields</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.safetyInformation.precautions.map(
                                    (each, i) => (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td className="w-25">
                                          <TableCell
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            data={each.label["@none"]}
                                            idField={`safetyInformation.precautions[${i}].label["@none"]`}
                                          />
                                        </td>
                                        <td className="w-25">
                                          <TableCell data={each.type}  idField={`safetyInformation.precautions[${i}].type`} />
                                        </td>

                                        <td>
                                          {each.regulatoryCode &&
                                            each.regulatoryCode.map(
                                              (each2, j) => (
                                                <DetailRow
                                                  enableEdit={true}
                                                  editFlag={editFlag}
                                                  label={"Regulatory Code"}
                                                  data={each2}
                                                  idField={`safetyInformation.precautions[${i}].regulatoryCode[${j}]`}
                                                />
                                              )
                                            )}

                                          {each.text && (
                                            <DetailRow
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              label={"Text"}
                                              data={each.text["@none"]}
                                              idField={`safetyInformation.precautions[${i}].text["@none"]`}
                                            />
                                          )}

                                          {each.placeholderWords &&
                                            each.placeholderWords.map(
                                              (each2, j) => (
                                                <DetailRow
                                                  enableEdit={true}
                                                  editFlag={editFlag}
                                                  label={"Placeholder Words"}
                                                  data={each2["@none"]}
                                                  idField={`safetyInformation.precautions[${i}].placeholderWords[${j}]["@none"]`}
                                                />
                                              )
                                            )}

                                          {each.subjectAtRisk && (
                                            <DetailRow
                                              label={"Subject At Risk"}
                                              data={
                                                each.subjectAtRisk.label[
                                                  "@none"
                                                ]
                                              }
                                              idField={`safetyInformation.precautions[${i}].subjectAtRisk.label["@none"]`}
                                            />
                                          )}

                                          {each.referencedPrecaution &&
                                            each.referencedPrecaution.code && (
                                              <DetailRow
                                                label={
                                                  "Referenced Precaution Code"
                                                }
                                                data={
                                                  each.referencedPrecaution
                                                    .code["@none"]
                                                }
                                                idField={`safetyInformation.precautions[${i}].referencedPrecaution.code["@none"]`}
                                              />
                                            )}

                                          {each.referencedPrecaution &&
                                            each.referencedPrecaution
                                              .statement && (
                                              <DetailRow
                                                label={
                                                  "Referenced Precaution Statement"
                                                }
                                                data={
                                                  each.referencedPrecaution
                                                    .statement["@none"]
                                                }
                                                idField={`safetyInformation.precautions[${i}].referencedPrecaution.statement["@none"]`}
                                              />
                                            )}

                                          {each.relatesTo && (
                                            <>
                                              <div>
                                                <span className="fw-bold">
                                                  Relates To
                                                </span>
                                                <LabelWithInfoTooltip
                                                  id={"relatesTo"}
                                                  info={
                                                    "The specific DFU/s to which the precaution relates if not product-level"
                                                  }
                                                />
                                              </div>

                                              <ul>
                                                {each.relatesTo.map(
                                                  (each2, i) => (
                                                    <li>
                                                      <TableCell  data={each2.label
                                                        ? each2.label["@none"]
                                                        : "-"}   idField={`safetyInformation.precautions[${i}].relatesTo.label["@none"]`}/>
                                                    </li>
                                                  )
                                                )}
                                              </ul>
                                            </>
                                          )}
                                          {each.isNote && (
                                            <DetailRow
                                              label={"Is Note"}
                                              data={each.isNote.toString()}
                                              idField={`safetyInformation.precautions[${i}].isNote`}
                                            />
                                          )}

                                          {/* {each.relatesTo &&
                                        <>Relates To</> &&
                                         each.relatesTo.map(
                                          (each2, i) => (
                                            <TableCell
                                              data={each2?.label["@none"]}
                                            />
                                          )
                                        )} */}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.clinicalMeasures && (
                      <>
                        <tr>
                          <th scope="row">10.3</th>
                          <td>
                            Clinical Measures
                            <LabelWithInfoTooltip
                              id={"clinicalMeasures"}
                              info={
                                "Other relevant clinical intervention safety information"
                              }
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.3"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.3"}
                            />
                          </td>
                          <td>
                            <div className="table-responsive">
                              <table class="table  mt-2 table-bordered  mb-0">
                                <thead>
                                  <tr className="table-light">
                                    <th scope="col">#</th>
                                    <th scope="col" className="w-25">
                                      Label
                                    </th>
                                    <th scope="col">Exposure Route</th>
                                    <th scope="col">Intervention Guidance</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.safetyInformation.clinicalMeasures.map(
                                    (each, i) => (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>
                                          <TableCell
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            data={each.label["@none"]}
                                            idField={`safetyInformation.clinicalMeasures[${i}].label["@none"]`}
                                          />
                                        </td>
                                        <td>
                                          {each.exposureRoute ? (
                                            <TableCell
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              data={each.exposureRoute["@none"]}
                                              idField={`safetyInformation.clinicalMeasures[${i}].exposureRoute["@none"]`}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {each.interventionGuidance ? (
                                            <TableCell
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              data={
                                                each.interventionGuidance[
                                                  "@none"
                                                ]
                                              }
                                              idField={`safetyInformation.clinicalMeasures[${i}].interventionGuidance["@none"]`}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.firefightingMeasures && (
                      <>
                        <tr>
                          <th scope="row">10.4</th>
                          <td>
                            Fire fighting Measures
                            <LabelWithInfoTooltip
                              id={"firefightingMeasures"}
                              info={"Fire fighting and safety measures"}
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.4"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.4"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.firefightingMeasures.map(
                                (each, i) => (
                                  <li>
                                    <TableCell
                                      enableEdit={true}
                                      editFlag={editFlag}
                                      data={each["@none"]}
                                      idField={`safetyInformation.firefightingMeasures[${i}]["@none"]`}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.safetyInterval && (
                      <>
                        <tr>
                          <th scope="row">10.5</th>
                          <td>
                            Safety Interval{" "}
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.5"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.5"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.safetyInterval.map(
                                (each2, i) => (
                                  <li>
                                    <DetailRow
                                      enableEdit={true}
                                      editFlag={editFlag}
                                      label={"Label"}
                                      data={each2.label["@none"]}
                                      idField={`safetyInformation.safetyInterval[${i}].label["@none"]`}
                                    />

                                    {each2.relativeEventDescription && (
                                      <DetailRow
                                        enableEdit={true}
                                        editFlag={editFlag}
                                        label={"Relative Event Description"}
                                        data={
                                          each2.relativeEventDescription[
                                            "@none"
                                          ]
                                        }
                                        idField={`safetyInformation.safetyInterval[${i}].relativeEventDescription["@none"]`}
                                      />
                                    )}

                                    {/* {each2.relativeToEvent && (
                                      <>
                                        <div className="fw-bold">
                                          Relative To Event:
                                        </div>
                                        <div className="table-responsive">
                                          <table class="table  mt-2  table-bordered mb-0">
                                            <thead>
                                              <tr className="table-light">
                                                <th scope="col">Label</th>
                                                <th scope="col">Crop</th>
                                                <th scope="col">Pest</th>
                                                <th scope="col">
                                                  General Life cycle Stage
                                                </th>
                                                <th scope="col">
                                                  Crop Growth Stage
                                                </th>
                                                <th scope="col">
                                                  Pest Development Stage
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr>
                                                <td>
                                                  <TableCell enableEdit={true} editFlag={editFlag} 
                                                    data={
                                                      each2.relativeToEvent
                                                        .label["@none"]
                                                    }
                                                    idField={`safetyInformation.safetyInterval[${i}].relativeToEvent.label["@none"]`}
                                                  />

                                                  {each2.relativeToEvent
                                                    .ploughedPriorToSowingPlanting && (
                                                    <>
                                                      <div className="fw-bold">
                                                        Ploughed Prior To Sowing
                                                        Planting:
                                                      </div>
                                                         <TableCell enableEdit={true} editFlag={editFlag}  data={each2.relativeToEvent.ploughedPriorToSowingPlanting.toString()}
                                                           idField={`safetyInformation.safetyInterval[${i}].relativeToEvent.ploughedPriorToSowingPlanting`} /> 
                                                      
                                                    </>
                                                  )}
                                                </td>
                                                <td>
                                                  <TableCell
                                                    data={
                                                      each2.relativeToEvent.crop
                                                        .label["@none"]
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <TableCell
                                                    data={
                                                      each2.relativeToEvent.pest
                                                        .label["@none"]
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <TableCell
                                                    data={
                                                      each2.relativeToEvent
                                                        .generalLifecycleStage
                                                        .label["@none"]
                                                    }
                                                  />
                                                </td>
                                                <td>
                                                  <TableCell
                                                    data={
                                                      each2.relativeToEvent
                                                        .cropGrowthStage.label[
                                                        "@none"
                                                      ]
                                                    }
                                                  />
                                                </td>{" "}
                                                <td>
                                                  <TableCell
                                                    data={
                                                      each2.relativeToEvent
                                                        .pestDevelopmentStage
                                                        .label["@none"]
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </>
                                    )} */}

                                    {each2.relativeToEvent && (
                                      <>
                                        <CropEvent
                                          editFlag={editFlag}
                                          data={each2.relativeToEvent}
                                          idField={`safetyInformation.safetyInterval[${i}].relativeToEvent`}
                                        />
                                      </>
                                    )}
                                    {each2.length && (
                                      <>
                                        <div className="fw-bold">Length: </div>

                                        <Dosage
                                          editFlag={editFlag}
                                          data={each2.length}
                                          idField={`safetyInformation.safetyInterval[${i}].length`}
                                        />
                                      </>
                                    )}
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.firstAidMeasures && (
                      <>
                        <tr>
                          <th scope="row">10.6</th>
                          <td>
                            First Aid Measures
                            <LabelWithInfoTooltip
                              id={"firstAidMeasures"}
                              info={"First aid information."}
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.6"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.6"}
                            />
                          </td>
                          <td>
                            <div className="table-responsive">
                              <table class="table  mt-2 table-bordered  mb-0">
                                <thead>
                                  <tr className="table-light">
                                    <th scope="col">#</th>
                                    <th scope="col" className="w-25">
                                      Label
                                    </th>
                                    <th scope="col">
                                      Exposure Route
                                      <LabelWithInfoTooltip
                                        id={"exposureRoute"}
                                        info={
                                          "The exposure route to which the clinical measure relates, if specific"
                                        }
                                      />
                                    </th>
                                    <th scope="col">
                                      Intervention Guidance
                                      <LabelWithInfoTooltip
                                        id={"interventionGuidance"}
                                        info={
                                          "The guidance information relating to the clinical safety measure"
                                        }
                                      />
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {data.safetyInformation.firstAidMeasures.map(
                                    (each, i) => (
                                      <tr>
                                        <th scope="row">{i + 1}</th>
                                        <td>
                                          <TableCell
                                            enableEdit={true}
                                            editFlag={editFlag}
                                            data={each.label["@none"]}
                                            idField={`safetyInformation.firstAidMeasures[${i}].label["@none"]`}
                                          />
                                        </td>
                                        <td>
                                          {each.exposureRoute ? (
                                            <TableCell
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              data={each.exposureRoute["@none"]}
                                              idField={`safetyInformation.firstAidMeasures[${i}].exposureRoute["@none"]`}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {each.interventionGuidance ? (
                                            <TableCell
                                              enableEdit={true}
                                              editFlag={editFlag}
                                              data={
                                                each.interventionGuidance[
                                                  "@none"
                                                ]
                                              }
                                              idField={`safetyInformation.firstAidMeasures[${i}].interventionGuidance["@none"]`}
                                            />
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.accidentalReleaseMeasures && (
                      <>
                        <tr>
                          <th scope="row">10.7</th>
                          <td>
                            Accidental Release Measures
                            <LabelWithInfoTooltip
                              id={"accidentalReleaseMeasures"}
                              info={
                                "Instructions in the case of accidental release of the product into the environment"
                              }
                            />
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.7"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.7"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.accidentalReleaseMeasures.map(
                                (each, i) => (
                                  <li>
                                    <TableCell
                                      enableEdit={true}
                                      editFlag={editFlag}
                                      data={each["@none"]}
                                      idField={`safetyInformation.accidentalReleaseMeasures[${i}]["@none"]`}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.safetyDatasheetLink && (
                      <tr>
                        <th scope="row">10.8</th>
                        <td>
                          Safety Datasheet Link
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.8"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"10.8"}
                          />
                        </td>
                        <td>
                          <Link
                            target="_blank"
                            to={data.safetyInformation.safetyDatasheetLink}
                          >
                            {data.safetyInformation.safetyDatasheetLink}
                          </Link>
                        </td>
                      </tr>
                    )}

                    {data.safetyInformation.ufiNo && (
                      <>
                        <tr>
                          <th scope="row">10.9</th>
                          <td>
                            UFI No
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.9"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.9"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.ufiNo.map((each, i) => (
                                <li>
                                  <AgentRow data={each} idField={`safetyInformation.ufiNo[${i}]`} />
                                </li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.clinicalEmergencyPhoneNumber && (
                      <>
                        <tr>
                          <th scope="row">10.10</th>
                          <td>
                            Clinical Emergency Phone Number
                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.10"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.10"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.clinicalEmergencyPhoneNumber.map(
                                (each, i) => (
                                  <li>
                                    <TableCell
                                      enableEdit={true}
                                      editFlag={editFlag}
                                      data={each["@none"]}
                                      idField={`safetyInformation.clinicalEmergencyPhoneNumber[${i}]["@none"]`}
                                    />
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}
                    {data.safetyInformation.clinicalEmergencyOrganisation && (
                      <>
                        <tr>
                          <th scope="row">10.10</th>
                          <td>
                            <span className="bg-warning">
                              Clinical Emergency Organisation
                            </span>
                            <LabelWithInfoTooltip
                              id={"clinicalEmergencyOrganisation"}
                              info={
                                "The organisation/s to be contacted in case of clinical emergency"
                              }
                            />

                            <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.10"}
                  />
                    <Comments
                              savedComments={savedComments}
                              commentsLoader={commentsLoader}
                              saveLoader={saveLoader}
                              onCommentsSave={saveComment}
                              onCommentReloader={loadComments}
                              secNo={"10.10"}
                            />
                          </td>
                          <td>
                            <ul>
                              {data.safetyInformation.clinicalEmergencyOrganisation.map(
                                (each, i) => (
                                  <li>
                                    <Suspense
                                      fallback={<div> Loading ...</div>}
                                    >
                                      <Agent data={each} idField={`safetyInformation.clinicalEmergencyOrganisation[${i}]`}/>
                                    </Suspense>
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      </>
                    )}

                    {data.safetyInformation.clinicalSupportOrganisation && (
                      <tr>
                        <th scope="row">10.11</th>
                        <td className="table-light">
                          Clinical Support Organisation
                          <LabelWithInfoTooltip
                            id={"clinicalSupportOrganisation"}
                            info={
                              "Organisation and phone number to contact for specialist toxicology clinical support information"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.11"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"10.11"}
                          />
                        </td>
                        <td>
                          <ul>
                            {data.safetyInformation.clinicalSupportOrganisation.map(
                              (each, i) => (
                                <li>
                                  <Suspense fallback={<div> Loading ...</div>}>
                                    <Agent data={each} idField={`safetyInformation.clinicalSupportOrganisation[${i}]`}/>
                                  </Suspense>
                                </li>
                              )
                            )}
                          </ul>
                        </td>
                      </tr>
                    )}
                    {data.safetyInformation
                      .environmentalEmergencyOrganisation && (
                      <tr>
                        <th scope="row">10.12</th>
                        <td className="table-light">
                          <span className="bg-warning">
                            Environmental Emergency Organisation
                          </span>
                          <LabelWithInfoTooltip
                            id={"environmentalEmergencyOrganisation"}
                            info={
                              "The organisation/s to be contacted in case of environmental emergency"
                            }
                          />
                          <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"10.12"}
                  />
                    <Comments
                            savedComments={savedComments}
                            commentsLoader={commentsLoader}
                            saveLoader={saveLoader}
                            onCommentsSave={saveComment}
                            onCommentReloader={loadComments}
                            secNo={"10.12"}
                          />
                        </td>
                        <td>
                          <ul>
                            {data.safetyInformation.environmentalEmergencyOrganisation.map(
                              (each, i) => (
                                <li>
                                  <Suspense fallback={<div> Loading ...</div>}>
                                    <Agent data={each} idField={`safetyInformation.environmentalEmergencyOrganisation[${i}]`}/>
                                  </Suspense>
                                </li>
                              )
                            )}
                          </ul>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </>
            )}

            {data.additionalInstructionsLink && (
              <tr ref={sectionRefs[10]}>
                <th scope="row">11</th>
                <td className="table-light">
                  Additional Instruction Link
                  <LabelWithInfoTooltip
                    id={"additionalInstructionsLink"}
                    info={
                      "Link to any additional instructions, which must be available for consumption or download online"
                    }
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"11"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"11"}
                  />
                </td>
                <td>
                  <Link target="_blank" to={data.additionalInstructionsLink}>
                    {data.additionalInstructionsLink}
                  </Link>
                </td>
              </tr>
            )}

            {data.preparationType && (
              <tr ref={sectionRefs[11]}>
                <th scope="row">12</th>
                <td className="table-light">
                  Preparation Type
                  <LabelWithInfoTooltip
                    id={"preparationType"}
                    info={"The preparation type of the formulation"}
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"12"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"12"}
                  />
                </td>
                <td>
                  <ul>
                    {data.preparationType.map((each, i) => (
                      <li>
                        <TableCell data={each.label["@none"]} idField={`preparationType[${i}].label["@none"]`}/>
                      </li>
                    ))}
                  </ul>
                </td>
              </tr>
            )}

            {data.manufacturer && (
              <tr ref={sectionRefs[12]}>
                <th scope="row">13</th>
                <td className="table-light">
                  Manufacturer
                  <LabelWithInfoTooltip
                    id={"manufacturer"}
                    info={
                      "The manufacturer of the registered formulation if different from the authorisation holder"
                    }
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"13"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"13"}
                  />
                </td>
                <td>
                  {data.manufacturer && Array.isArray(data.manufacturer) ? (
                    <Suspense fallback={<div> Loading ...</div>}>
                      {" "}
                      <AgentMultiple data={data.manufacturer} idField={`manufacturer`} />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div> Loading ...</div>}>
                      <Agent data={data.manufacturer} idField={`manufacturer`}/>
                    </Suspense>
                  )}
                </td>
              </tr>
            )}

            {data.marketedBy && (
              <tr ref={sectionRefs[13]}>
                <th scope="row">14</th>
                <td className="table-light">
                  Marketed By
                  <LabelWithInfoTooltip
                    id={"marketedBy"}
                    info={
                      "The organisation who supply or market the product if different from the authorisation holder"
                    }
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"14"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"14"}
                  />
                </td>
                <td>
                  {data.marketedBy && Array.isArray(data.marketedBy) ? (
                    <Suspense fallback={<div> Loading ...</div>}>
                      {" "}
                      <AgentMultiple data={data.marketedBy} idField={`marketedBy`}/>
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div> Loading ...</div>}>
                      <Agent data={data.marketedBy} idField={`marketedBy`}/>
                    </Suspense>
                  )}
                </td>
              </tr>
            )}

            {data.brand && (
              <>
                <tr ref={sectionRefs[14]}>
                  <th scope="row">15</th>
                  <td className="table-light">
                    Brand
                    <LabelWithInfoTooltip
                      id={"brand"}
                      info={
                        "The brand under which the product is marketed if different from the authorisation holder name"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"15"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"15"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.brand}
                      idField={`brand`}
                    />
                  </td>
                </tr>
              </>
            )}

            {data.colour && (
              <>
                <tr ref={sectionRefs[15]}>
                  <th scope="row">16</th>
                  <td className="table-light">
                    Colour
                    <LabelWithInfoTooltip
                      id={"colour"}
                      info={
                        "The colour of the formulation (provided as a check for the grower in case the product package has been reused)"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"16"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"16"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.colour["@none"]}
                      idField={`colour["@none"]`}
                    />
                  </td>
                </tr>
              </>
            )}

            {data.odour && (
              <>
                <tr ref={sectionRefs[16]}>
                  <th scope="row">17</th>
                  <td className="table-light">
                    Odour
                    <LabelWithInfoTooltip
                      id={"odour"}
                      info={
                        "The odour of the formulation (provided as a check for the grower in case the product package has been reused)"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"17"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"17"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.odour["@none"]}
                      idField={`odour["@none"]`}
                    />
                  </td>
                </tr>
              </>
            )}

            {data.unNumber && (
              <>
                <tr ref={sectionRefs[17]}>
                  <th scope="row">18</th>
                  <td className="table-light">
                    UN Number
                    <LabelWithInfoTooltip
                      id={"unNumber"}
                      info={"The UN number for the formulation"}
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"18"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"18"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.unNumber}
                      idField={`unNumber`}
                    />
                  </td>
                </tr>
              </>
            )}

            {data.storageClassificationCode && (
              <>
                <tr ref={sectionRefs[18]}>
                  <th scope="row">19</th>
                  <td className="table-light">
                    Storage Classification Code
                    <LabelWithInfoTooltip
                      id={"storageClassificationCode"}
                      info={
                        "The storage classification code for the formulation"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"19"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"19"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.storageClassificationCode}
                      idField={`storageClassificationCode`}
                    />
                  </td>
                </tr>
              </>
            )}

            {data.modeOfActionDescription && (
              <>
                <tr ref={sectionRefs[19]}>
                  <th scope="row">20</th>
                  <td className="table-light">
                    Mode of Action Description
                    <LabelWithInfoTooltip
                      id={"modeOfActionDescription"}
                      info={
                        "A textual description of the mode of action of the complete formulation"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"20"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"20"}
                    />
                  </td>
                  {/* <td>{data.modeOfActionDescription["@none"]}</td> */}

                  <td>
                    {data.modeOfActionDescription["@none"] ? (
                      <TableCell
                        enableEdit={true}
                        editFlag={editFlag}
                        data={data.modeOfActionDescription["@none"]}
                        idField={`modeOfActionDescription["@none"]`}
                      />
                    ) : (
                      <div>-</div>
                    )}
                  </td>
                </tr>
              </>
            )}

            {data.resistanceInformation && (
              <>
                <tr ref={sectionRefs[20]}>
                  <th scope="row">21</th>
                  <td className="table-light">
                    Resistance Information
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"21"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"21"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.resistanceInformation.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`resistanceInformation[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.cropRotationGuidance && (
              <>
                <tr ref={sectionRefs[21]}>
                  <th scope="row">22</th>
                  <td className="table-light">
                    Crop Rotation Guidance
                    <LabelWithInfoTooltip
                      id={"cropRotationGuidance"}
                      info={
                        "Any additional crop rotation guidance. Information regarding phytotoxicity to successional crops should also be captured via adverse effects. Information relating to resistance management should also be captured under resistance management information."
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"22"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"22"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.cropRotationGuidance.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`cropRotationGuidance[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.cropFailureGuidance && (
              <>
                <tr ref={sectionRefs[22]}>
                  <th scope="row">23</th>
                  <td className="table-light">
                    Crop Failure Guidance
                    <LabelWithInfoTooltip
                      id={"cropFailureGuidance"}
                      info={"Any guidance relating to crop failure."}
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"23"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"23"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.cropFailureGuidance.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`cropFailureGuidance[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.summaryNotes && (
              <>
                <tr ref={sectionRefs[23]}>
                  <th scope="row">24</th>
                  <td className="table-light">
                    Summary Notes
                    <LabelWithInfoTooltip
                      id={"summaryNotes"}
                      info={
                        "Any introductory text, product summary or general overview information which appears on the label"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"24"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"24"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.summaryNotes.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`summaryNotes[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.otherMandatoryInformation && (
              <>
                <tr ref={sectionRefs[24]}>
                  <th scope="row">25</th>
                  <td className="table-light">
                    Other Mandatory Information
                    <LabelWithInfoTooltip
                      id={"otherMandatoryInformation"}
                      info={
                        "Any other mandatory information about the registered formulation which must be displayed on the label"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"25"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"25"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.otherMandatoryInformation.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each["@none"]}
                            idField={`otherMandatoryInformation[${i}]["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}

            {data.labelMetadata && (
              <>
                <tr ref={sectionRefs[25]}>
                  <th scope="row">26</th>
                  <td className="table-light">
                    Label Metadata
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"26"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"26"}
                    />
                  </td>
                  <td>
                    <table class="table  mt-2  table-bordered mb-0">
                      <tbody>
                        <tr>
                          <th scope="row" rowSpan="6" style={{ width: "10%" }}>
                            {1}
                          </th>
                          <th scope="col" className="w-25">
                            Label
                          </th>
                          <td>
                            <TableCell
                              enableEdit={true}
                              editFlag={editFlag}
                              data={data.labelMetadata.label["@none"]}
                              idField={`labelMetadata.label["@none"]`}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Label Version</th>
                          <td>
                            <TableCell
                              enableEdit={true}
                              editFlag={editFlag}
                              data={data.labelMetadata.labelVersion}
                              idField={`labelMetadata.labelVersion`}
                            />
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Pdf Link</th>
                          <td>
                            {data.labelMetadata.pdfLink ? (
                              <Link
                                target="_blank"
                                to={data.labelMetadata.pdfLink}
                              >
                                {data.labelMetadata.pdfLink}
                              </Link>
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">Publication Status</th>
                          <td>
                            {data.labelMetadata?.publicationStatus ? (
                              <TableCell
                                data={
                                  data.labelMetadata.publicationStatus.status[
                                    "@none"
                                  ]
                                }
                                idField={`labelMetadata.publicationStatus.status["@none"]`}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">
                            <span className="bg-warning">
                              Label Version Valid From
                            </span>
                            <LabelWithInfoTooltip
                              id={"labelVersionValidFrom"}
                              info={
                                "The date from which this version of the label is valid"
                              }
                            />
                          </th>
                          <td>
                            {data.labelMetadata?.labelVersionValidFrom ? (
                              <TableCell
                                // enableEdit={true}
                                // editFlag={editFlag}
                                data={data.labelMetadata.labelVersionValidFrom}
                                 idField={`labelMetadata.labelVersionValidFrom`}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th scope="col">
                            <span className="bg-warning">
                              Label Version Valid To
                            </span>
                            <LabelWithInfoTooltip
                              id={"labelVersionValidTo"}
                              info={
                                "The date until which this version of the label is valid"
                              }
                            />
                          </th>
                          <td>
                            {data.labelMetadata?.labelVersionValidTo ? (
                              <TableCell
                                // enableEdit={true}
                                // editFlag={editFlag}
                                data={data.labelMetadata.labelVersionValidTo}
                                 idField={`labelMetadata.labelVersionValidTo`}
                              />
                            ) : (
                              "-"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </>
            )}

            {data.productPackage && (
              <>
                <tr ref={sectionRefs[26]}>
                  <th scope="row">
                    27
                    <ExpandToggle
                      boolToggle={show27}
                      onToggle={(e) => setShow27(!show27)}
                    />
                  </th>
                  <td className="table-light">
                    Product Package
                    <LabelWithInfoTooltip
                      id={"productPackage"}
                      info={"The product package information"}
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"27"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"27"}
                    />
                  </td>
                  <td></td>
                </tr>

                {show27 &&
                  data.productPackage.map((each, m) => (
                    <>
                      <tr>
                        <th scope="row">27.{m + 1}</th>
                        <td>
                          <DetailRow
                            enableEdit={true}
                            editFlag={editFlag}
                            label={"Label"}
                            data={each.label["@none"]}
                            idField={`productPackage[${m}].label["@none"]`}
                          />
                        </td>

                        <td>
                          {each.packagingMaterial && (
                            <>
                              <div>
                                <span className="fw-bold">
                                  Packaging Material:
                                </span>
                                <LabelWithInfoTooltip
                                  id={"packagingMaterialLabel"}
                                  info={
                                    "The material out of which the package or container has been fabricated"
                                  }
                                />
                              </div>
                              {each.packagingMaterial
                                .packagingMaterialLabel && (
                                <>
                                  <TableCell data={
                                    each.packagingMaterial
                                      .packagingMaterialLabel["@none"]
                                  } idField={`productPackage[${m}].packagingMaterial.packagingMaterialLabel["@none"]`}/>
                                </>
                              )}
                              <div>
                                <span className="fw-bold">
                                  Packaging MaterialCode:{" "}
                                </span>
                                <LabelWithInfoTooltip
                                  id={"packagingMaterialCode"}
                                  info={
                                    "The code for material out of which the package or container has been fabricated"
                                  }
                                />
                              </div>
                              {each.packagingMaterial.packagingMaterialCode && (
                                <>
                                <TableCell data={each.packagingMaterial.packagingMaterialCode} idField={`productPackage[${m}].packagingMaterial.packagingMaterialCode`} />
                                </>
                              )}
                            </>
                          )}
                          {/** new inclusion  */}
                          {each.packageType && (
                            <>
                              <div className="fw-bold">Package Type: </div>
                              {each.packageType.label && (
                                <><TableCell data= {each.packageType.label["@none"]} idField={`productPackage[${m}].packageType.label["@none"]`}/></>
                              )}
                            </>
                          )}
                          {each.packshotImage && (
                            <>
                              <div>
                                <span className="fw-bold">Packshot Image:</span>
                                <LabelWithInfoTooltip
                                  id={"packshotImage"}
                                  info={
                                    "An image in the packshot, for use in product checks by the grower"
                                  }
                                />
                              </div>
                              {each.packshotImage.map((each2, i) => (
                                <>
                                  <Link target="_blank" to={each2}>
                                    {each2}
                                  </Link>
                                </>
                              ))}
                            </>
                          )}

                          {each.gtin && (
                            <>
                              <div className="fw-bold">Gtin: </div>

                              <AgentRow data={each.gtin} idField={`productPackage[${m}].gtin`} />
                            </>
                          )}

                          {each.size && (
                            <>
                              <div>
                                <span className="fw-bold">Size:</span>
                                <LabelWithInfoTooltip
                                  id={"size"}
                                  info={
                                    "The size of the product package, typically specified by mass or volume"
                                  }
                                />
                              </div>
                              <Dosage
                                editFlag={editFlag}
                                data={each.size}
                                idField={`productPackage[${m}].size`}
                              />
                            </>
                          )}
                          {each.netContent && (
                            <>
                              <div className="fw-bold margin-top-2 margin-bottom-2">
                                <span className="bg-warning">Net Content:</span>
                                <LabelWithInfoTooltip
                                  id={"netContent"}
                                  info={
                                    "The net content of the product package"
                                  }
                                />
                              </div>

                              <Dosage
                                editFlag={editFlag}
                                data={each.netContent}
                                idField={`productPackage[${m}].netContent`}
                              />
                            </>
                          )}
                          {each.packageDisposalStatement && (
                            <>
                              <div className="fw-bold">
                                <span className="bg-warning">
                                  Package Disposal Statement:
                                </span>
                                <LabelWithInfoTooltip
                                  id={"packageDisposalStatement"}
                                  info={
                                    "Any package disposal guidance which applies to a specific packaging format rether than the overall product"
                                  }
                                />
                              </div>
                              <ul>
                                {each.packageDisposalStatement.map(
                                  (each, j) => (
                                    <li>
                                      <TableCell
                                        enableEdit={true}
                                        editFlag={editFlag}
                                        data={each["@none"]}
                                        idField={`productPackage[${m}].packageDisposalStatement[${j}]["@none"]`}
                                      />
                                    </li>
                                  )
                                )}
                              </ul>
                            </>
                          )}
                          {each.designedForReuseByAuthorisationHolder && (
                            <DetailRow
                              label={
                                "Designed For Reuse By Authorisation Holder"
                              }
                              data={each.designedForReuseByAuthorisationHolder.toString()}
                              tooltipId={
                                "designedForReuseByAuthorisationHolder"
                              }
                              tooltipInfo={
                                "Flags whether or not the packaging has been authorised for reuse by the manufacturer"
                              }
                              idField={`productPackage[${m}].designedForReuseByAuthorisationHolder`} 
                            />
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
              </>
            )}

            {data.productActionType && (
              <>
                <tr ref={sectionRefs[27]}>
                  <th scope="row">28</th>
                  <td className="table-light">
                    Product Action Type
                    <LabelWithInfoTooltip
                      id={"productActionType"}
                      info={
                        "The high-level product type (insecticide, herbicide, fungicide or growth regulator) - todo: review for controlled vocab"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"28"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"28"}
                    />
                  </td>
                  <td>
                    {data.productActionType &&
                    Array.isArray(data.productActionType) ? (
                      <>
                        <ul>
                          {data.productActionType.map((each, i) => (
                            <li>
                              <TableCell data={each.label["@none"]}  idField={`productActionType[${i}].label["@none"]`}  />
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <TableCell data={data.productActionType.label["@none"]} idField={`productActionType.label["@none"]`}  />
                    )}
                  </td>
                </tr>
              </>
            )}
            {data.dangerousIngredientsWarning && (
              <>
                <tr ref={sectionRefs[28]}>
                  <th scope="row">29</th>
                  <td className="table-light">
                    Dangerous Ingredients Warning
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"29"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"29"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.dangerousIngredientsWarning.statement["@none"]}
                      idField={`dangerousIngredientsWarning.statement["@none"]`}
                    />
                  </td>
                </tr>
              </>
            )}
            {data.successionalCropGuidance && (
              <>
                <tr ref={sectionRefs[29]}>
                  <th scope="row">30</th>
                  <td className="table-light">
                    <span className="bg-warning">
                      Successional Crop Guidance
                    </span>
                    <LabelWithInfoTooltip
                      id={"successionalCropGuidance"}
                      info={
                        "Guidance relating specifically to successional sowing in the target field"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"30"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"30"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.successionalCropGuidance["@none"]}
                      idField={`successionalCropGuidance["@none"]`}
                    />
                  </td>
                </tr>
              </>
            )}
            {data.directionsForTransport && (
              <>
                <tr ref={sectionRefs[30]}>
                  <th scope="row">31</th>
                  <td className="table-light">
                    <span className="bg-warning">Directions For Transport</span>
                    <LabelWithInfoTooltip
                      id={"directionsForTransport"}
                      info={
                        "Distribution guidance relating to the transportation of the product"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"31"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"31"}
                    />
                  </td>
                  <td>
                    <TableCell
                      enableEdit={true}
                      editFlag={editFlag}
                      data={data.directionsForTransport["@none"]}
                      idField={`directionsForTransport["@none"]`}
                    />
                  </td>
                </tr>
              </>
            )}
            {data.technicalSupportOrganisation && (
              <tr ref={sectionRefs[31]}>
                <th scope="row">32</th>
                <td className="table-light">
                  <span className="bg-warning">
                    Technical Support Organisation
                  </span>
                  <LabelWithInfoTooltip
                    id={"technicalSupportOrganisation"}
                    info={
                      "The organisation to be contacted by growers for technical support, if specified"
                    }
                  />
                  <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"32"}
                  />
                    <Comments
                    savedComments={savedComments}
                    commentsLoader={commentsLoader}
                    saveLoader={saveLoader}
                    onCommentsSave={saveComment}
                    onCommentReloader={loadComments}
                    secNo={"32"}
                  />
                </td>
                <td>
                  {data.technicalSupportOrganisation &&
                  Array.isArray(data.technicalSupportOrganisation) ? (
                    <Suspense fallback={<div> Loading ...</div>}>
                      <AgentMultiple data={data.technicalSupportOrganisation} idField={`technicalSupportOrganisation`}  />
                    </Suspense>
                  ) : (
                    <Suspense fallback={<div> Loading ...</div>}>
                      <Agent data={data.technicalSupportOrganisation} idField={`technicalSupportOrganisation`}/>
                    </Suspense>
                  )}
                </td>
              </tr>
            )}
            {/* {data.disclaimer && (
              <>
                <tr ref={sectionRefs[32]}>
                  <th scope="row">33</th>
                  <td className="table-light">
                    Disclaimer
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"33"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"33"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.disclaimer.map((each, i) => (
                          <li>
                          <TableCell enableEdit={true} editFlag={editFlag}  data={each["@none"]}  idField={`disclaimer[${i}]["@none"]`}/>
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )} */}
            {data.coformulantsQbaStatement && (
              <>
                <tr ref={sectionRefs[32]}>
                  <th scope="row">33</th>
                  <td className="table-light">
                    <span className="bg-warning">
                      Coformulants Qba Statement
                    </span>
                    <LabelWithInfoTooltip
                      id={"coformulantsQbaStatement"}
                      info={
                        "Any statement regarding coformulants if required in a territory"
                      }
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"33"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"33"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.coformulantsQbaStatement.map((each, i) => (
                        <li>
                          <TableCell
                            enableEdit={true}
                            editFlag={editFlag}
                            data={each.label["@none"]}
                            idField={`coformulantsQbaStatement[${i}].label["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}
            {data.materialState && (
              <>
                <tr ref={sectionRefs[33]}>
                  <th scope="row">34</th>
                  <td className="table-light">
                    <span className="bg-warning"> Material State</span>
                    <LabelWithInfoTooltip
                      id={"materialState"}
                      info={"The material state/s of the product"}
                    />
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"34"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"34"}
                    />
                  </td>
                  <td>
                    <ul>
                      {data.materialState.map((each, i) => (
                        <li>
                          <TableCell
                            data={each.label["@none"]}
                            idField={`materialState[${i}].label["@none"]`}
                          />
                        </li>
                      ))}
                    </ul>
                  </td>
                </tr>
              </>
            )}
            {data.manufacturingLocation && (
              <>
                <tr ref={sectionRefs[34]}>
                  <th scope="row">35</th>
                  <td className="table-light">
                    <span className="bg-warning"> Manufacturing Location</span>
                    <ShowSectioncomment
                    handleShowAllComment={handleShowAllComment}
                    slNo={"35"}
                  />
                    <Comments
                      savedComments={savedComments}
                      commentsLoader={commentsLoader}
                      saveLoader={saveLoader}
                      onCommentsSave={saveComment}
                      onCommentReloader={loadComments}
                      secNo={"35"}
                    />
                  </td>
                  <td>
                    {Array.isArray(data.manufacturingLocation) ? (
                      <Suspense fallback={<div> Loading ...</div>}>
                        <AgentMultiple data={data.manufacturingLocation}  idField={`manufacturingLocation`}/>
                      </Suspense>
                    ) : (
                      <Suspense fallback={<div> Loading ...</div>}>
                        <Agent data={data.manufacturingLocation}  idField={`manufacturingLocation`}/>
                      </Suspense>
                    )}
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="flex justify-content-end">
        <Card>
          <Card.Header>
            <Button variant="secondary" onClick={toggleComponents}>
              <FontAwesomeIcon
                className="me-2"
                icon={showCommentTree ? faToggleOn : faToggleOff}
              />
              {"Switch to "}
              {showCommentTree
                ? "order by added on"
                : "order by section number"}
            </Button>
          </Card.Header>
          <Card.Body>
            {showCommentTree ? (
              <CommentsWithTree
                label={label}
                data={savedComments}
                sectionRef={JumpComments}
              />
            ) : (
              <Allcomments data={savedComments} sectionRef={JumpComments} />
            )}
          </Card.Body>
        </Card>
      </div>
      <br></br>
      <ValidationComments
        label={label}
        data={savedComments}
        sectionRef={JumpValidationComment}
      />
      <EditedReport label={label} data={""} sectionRef={JumpEditedReport} />
      <CvAdditionComments
        label={label}
        data={savedComments}
        sectionRef={JumpCvAdditionComment}
      />
      <FloatingSaveButton />
      <Modal centered show={showAlert} onHide={() => setShowAlert(false)}>
        <Modal.Body>
          <strong>{alertMessage}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setShowAlert(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TableView;
