import React from "react";
import classNames from "classnames";
import styles from "./Layout.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faYoutube } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className={styles.root}>
      <section className="container-xxl" >

        <div>
          <div
            className={classNames(
              styles.footer2,
              "d-flex align-items-center flex-row"
            )}
          >
            <div className="col-lg-6 text-start">
              Copyright Ⓒ {new Date().getFullYear()} Molecular Connections Pvt.
              Ltd. | All Rights Reserved | Version 3.0
            </div>
            <div className="col-lg-3 text-start">
              <a
                href="https://in.linkedin.com/company/molecular-connections"
                rel="noreferrer"
                target="_blank"
                className="text-reset"
              >

                <FontAwesomeIcon
                  icon={faLinkedinIn}
                  className="mx-3" size="lg"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCesUFzA8D8us3A__S73MrRQ"
                rel="noreferrer"
                target="_blank"
                className="text-reset"
              >
                
                <FontAwesomeIcon
                  icon={faYoutube}
                   size="lg"
                />
              </a>
            </div>
            <div className="col-lg-3 text-end">
              <a
                href="https://molecularconnections.com/privacy-policy/"
                rel="noreferrer"
                target="_blank"
                className="text-reset"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
