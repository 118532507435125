import LabelWithInfoTooltip from "../Components/InfoToolTips";
import TableCell from "../TableCell";
import React, { Suspense, lazy } from "react";
const Agent = React.lazy(()=> import("./Agent"));

const AgentRow = ({ data , idField}) => {
  return (
    <>
      {data && (
        <>
          <div className="table-responsive">
            <table class="table  mt-2  table-bordered mb-0">
              <thead>
                <tr className="table-light">
                  <th scope="col">Type</th>
                  <th scope="col">Value</th>
                  {data && data.type === "NonPersistentIdentifier" && (
                    <>
                      <th scope="col">
                        Valid From
                        <LabelWithInfoTooltip
                          id={"agent-validFrom"}
                          info={"The date from which the identifier is valid"}
                        />
                      </th>
                      <th scope="col">
                        Valid To
                        <LabelWithInfoTooltip
                          id={"agent-validTo"}
                          info={"The date until which the identifier is valid"}
                        />
                      </th>
                      <th scope="col">Version No
                      <LabelWithInfoTooltip
                          id={"versionNo"}
                          info={"The version number of the identifier"}
                        />
                      </th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <TableCell label={"Type"} data={data.type} idField={`${idField}.type`} />
                  </td>
                  <td>
                    {data.value && (
                      <TableCell label={"Value"} data={data.value} idField={`${idField}.value`} />
                    )}
                    {data.label && (
                      <TableCell label={"Value"} data={data.label} idField={`${idField}.label`} />
                    )}
                  </td>
                  {data && data.type === "NonPersistentIdentifier" && (
                    <>
                      <td>
                        <TableCell label={"Valid From"} data={data.validFrom}  idField={`${idField}.vaildFrom`}/>
                      </td>
                      <td>
                        <TableCell label={"Valid To"} data={data.validTo} idField={`${idField}.validTo`} />
                      </td>

                      <td>
                        <TableCell label={"Version No"} data={data.versionNo}  idField={`${idField}.versionNo`}/>
                      </td>
                    </>
                  )}
                </tr>
              </tbody>
            </table>
          </div>

          {data.authority && (
            <>
              <div className="fw-bold mt-2">
                Authority
                <LabelWithInfoTooltip
                  id={"authority"}
                  info={
                    "The external authority who has published or assigned the identifier"
                  }
                />
              </div>
              <Suspense fallback={<div> Loading ...</div>}>
                <Agent data={data.authority} idField={`${idField}.authority`}/>
              </Suspense>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AgentRow;
