import React, { useState } from "react";
import axios from "axios";
import AxiosService from "../../../Utils/AxiosService";
import LocalStorageService from "../../../Auth/auth_service/AuthServiceStorage";
import { toast } from "react-toastify";
import {faBookOpen, faBookOpenReader, faDownload, faEye, faSpinner} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const InputFileLinks = ({label}) => {

    const apiService = new AxiosService();
    const authDetails = LocalStorageService.getAuthDetails();
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState({});

   const fetchFiles = () => {
  
    setLoading(true);
  
      const auth_details = JSON.parse(authDetails);
  
      apiService
        .fetchInputFiles(label)
        .then((response) => {
            setLoading(false);
          if (response.data) {
            setFiles(response.data); 
            setShowModal(true);
          }
        })
        .catch((error) => {
            setLoading(false);
  
          if (error?.response?.data?.message) {
            toast.error(error.response.data.message);
          } else {
            toast.error("Something went wrong!");
          }
        });
    };


  // Trigger the file download
  const downloadFile = async (filename) => {
    setLoadingFiles((prevState) => ({ ...prevState, [filename]: 'download' }));
    setLoading(true);
    apiService
      .downloadFile(filename, label)
      .then((response) => {
        if (response && response.data) {
          const fileBlob = new Blob([response.data], {
            type: response.headers["content-type"],
          });

          const downloadLink = document.createElement("a");
          const fileURL = URL.createObjectURL(fileBlob);

          downloadLink.href = fileURL;
          downloadLink.download = filename;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
          URL.revokeObjectURL(fileURL);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .finally(() => {
        setLoadingFiles((prevState) => {
          const newState = { ...prevState };
          delete newState[filename];
          return newState;
        });
      }); 
  };

  const isViewableFile = (file) => {
    return file.endsWith(".pdf");
  };

  const viewFile = async (filename) => {
    setLoadingFiles((prevState) => ({ ...prevState, [filename]: 'view' }));
    setLoading(true);
    apiService
      .viewFile(filename, label)
      .then((response) => {
        if (response && response.data) {
            const fileBlob = new Blob([response.data], {
                type: response.headers["content-type"],
              });
              const fileURL = URL.createObjectURL(fileBlob);
              setLoading(false);
              window.open(fileURL, "_blank");
              setTimeout(() => URL.revokeObjectURL(fileURL), 100);
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error?.response?.data?.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error("Something went wrong!");
        }
      })
      .finally(() => {
        setLoadingFiles((prevState) => {
          const newState = { ...prevState };
          delete newState[filename];
          return newState;
        });
      }); 
  };

  return (
    <div>
      <button
        className="download-button"
        onClick={fetchFiles}
        disabled={loading}
        title="View Manuals"
      >
        
        
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : <FontAwesomeIcon icon={faBookOpenReader} size="lg" />}
      </button>

      {/* Modal for displaying the files */}
      {showModal && (
        <div
          className="modal fade show d-block"
          tabIndex="-1"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Files</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                {files.length === 0 ? (
                  <p>No files available.</p>
                ) : (
                  <ul className="list-group">
                    {files.map((file, index) => (
                      <li key={index} className="list-group-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            {file}
                          </div>
                          <div>
                          {isViewableFile(file) && (
                            <button
                              className="btn btn-sm btn-outline-secondary me-2"
                              onClick={() => viewFile(file)}
                              title={`View ${file}`}
                            >
                               {loadingFiles[file] === 'view' ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faEye} />
                  )}
                            </button>
                          )}
                           <button
                              className="btn btn-sm btn-outline-success"
                              onClick={() => downloadFile(file)}
                              title={`Download ${file}`}
                            >{loadingFiles[file] === 'download' ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              <FontAwesomeIcon icon={faDownload} />
                            )}
                            </button>
                          </div>
                          
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InputFileLinks;