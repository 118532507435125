import React, { useState, useEffect, useRef, Suspense, lazy, Fragment , memo  } from 'react';
import ResponsivePagination from 'react-responsive-pagination';
import { toast } from "react-toastify";
import AxiosService from '../../../Utils/AxiosService';
import { Card, Spinner } from 'react-bootstrap';

const DirectionsForUse = lazy(() => import("./DirectionsForUse"));
const MemoizedDirectionsForUse =React.memo(DirectionsForUse);

const DirectionForUseParentElement = ({
  directionData,
  savedComments,
  commentsLoader,
  saveLoader,
  saveComment,
  loadComments,
  editFlag,
  labelId,
  handleShowAllComment,expandAll , handleExpandAll
}) => {
  const apiService = new AxiosService();
  const [data, setData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const componentRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      try {
        // const countResponse = await apiService.getDirectionForUseDataCount(labelId);
        // setTotalItems(countResponse.data);

        const dataResponse = await apiService.getDirectionForUseData(labelId);
        setData(dataResponse.data);
      } catch (error) {
        const message = error?.response?.data?.message || "Something went wrong!";
        toast.error(message);
      } finally {
        setLoader(false);
      }
    };

    fetchData();
  }, [labelId]);

  const getDirectionForUseData = async () => {
    setLoader(true);
    try {
      const response = await apiService.getDirectionForUseData(labelId);
      setData(response.data);
    } catch (error) {
      const message = error?.response?.data?.message || "Something went wrong!";
      toast.error(message);
    } finally {
      setLoader(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getDirectionForUseData(pageNumber);
  };


  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };


  const [expandedIndexes, setExpandedIndexes] = useState(new Array(data.length).fill(true)); 
  const [isFirstTime , setIsFirstTime]= useState(true);

  const onToggle = (index) => {
    // let updatedIndexes = [...expandedIndexes];
    // if(updatedIndexes[index]){
    //   updatedIndexes[index] = !updatedIndexes[index];
    // }else{
    //   updatedIndexes = new Array(expandedIndexes.length).fill(false);
    //   updatedIndexes[index] = true;
    // }
    // setExpandedIndexes(updatedIndexes);
    // const updatedIndexes = new Array(expandedIndexes.length).fill(false);
    // updatedIndexes[index] = true;
    // setExpandedIndexes(updatedIndexes);
     const updatedIndexes = [...expandedIndexes];
     updatedIndexes[index] = !updatedIndexes[index];
     setExpandedIndexes(updatedIndexes);
  };

  useEffect(() =>{
    if(expandAll){
        setExpandedIndexes(new Array(data.length).fill(true));
    }else{
      setIsFirstTime(false);
        setExpandedIndexes(new Array(data.length).fill(false));
       
    }
  },[expandAll ,data.length]);
 


  useEffect(() => {
    console.log("dfuLength :" ,data.length)
    if (currentIndex < data.length) {
      const timer = setTimeout(() => {
        if (componentRef.current) {
          componentRef.current.focus();
        }
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, data.length]);


  const renderPagination = () => (
    <tr>
      <td colSpan={3}>
        <div className='p-2'>
          <ResponsivePagination
            total={Math.ceil(totalItems / 20)}
            current={currentPage}
            onPageChange={handlePageChange}
            maxWidth={70}
            previousLabel="Previous"
            nextLabel="Next"
          />
        </div>
      </td>
    </tr>
  );

  const renderLoading = () => (
    <tr>
      <td colSpan={3}>
        <Card className="mt-6">
          <Card.Body>
            <div className="text-center p-3">
              <Spinner animation="border" />
            </div>
          </Card.Body>
        </Card>
      </td>
    </tr>
  );

  const renderDirections = () => (
    <>
      {data.map((item, index) => {
        if (index > currentIndex) return null;

        return (
          <Suspense key={index} fallback={<div>{renderLoading()}</div>}>
            <Fragment>
              <DirectionsForUse
                savedComments={savedComments}
                commentsLoader={commentsLoader}
                saveLoader={saveLoader}
                saveComment={saveComment}
                data={item}
                loadComments={loadComments}
                editFlag={editFlag}
                i={index}
                handleShowAllComment={handleShowAllComment}
                expandedIndex={expandedIndex} 
                onToggle={onToggle} 
                expandedIndexes={expandedIndexes}
               
               
              />
            </Fragment>
          </Suspense>
        );
      })}
    </>
  );
  
  

  // const renderDirections = () => {
  //   return data
  //     .filter((_, index) => index <= currentIndex) 
  //     .map((item, index) => (
  //       <Suspense key={`direction-${index}`} fallback={<div>{renderLoading()}</div>}>
  //         <MemoizedDirectionsForUse
  //           savedComments={savedComments}
  //           commentsLoader={commentsLoader}
  //           saveLoader={saveLoader}
  //           saveComment={saveComment}
  //           data={item}
  //           loadComments={loadComments}
  //           editFlag={editFlag}
  //           i={index}
  //           currentPage={currentPage}
  //         />
  //       </Suspense>
  //     ));
  // };

  return (
    <>
      {/* {renderPagination()} */}
      {loader ? renderLoading() : renderDirections()}
    </>
  );
};

export default DirectionForUseParentElement;
